<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
  </span>
</button>

<mat-menu #userActions="matMenu" [xPosition]="'before'">
  <button mat-menu-item>
    <div class="w-full flex flex-col justify-center items-start leading-none">
      <span class="w-full text-start">{{ "logged_in" | transloco }}</span>
      <span class="mt-1.5 text-md font-medium">{{ user.name }}</span>
    </div>
  </button>
  <mat-divider class="my-2"></mat-divider>

  <a
    mat-menu-item
    [href]="salonWebSite()"
    [dir]="'_dir' | transloco"
    target="_blank"
  >
    <div class="min-w-full flex justify-center items-start gap-2">
      <div class="min-w-full flex justify-center items-start gap-2">
        <mat-icon svgIcon="launch" class="m-0"></mat-icon>
        <span>{{ "go_website_salon" | transloco }}</span>
      </div>
    </div>
  </a>

  <button mat-menu-item [dir]="'_dir' | transloco" (click)="onSelectLang()">
    <div class="min-w-full flex justify-center items-start gap-2">
      <img
        class="w-6 h-6"
        [src]="'assets/images/flags/' + lang()?.id + '.svg'"
        [alt]="'Flag image for ' + lang()?.title"
      />
      <span>{{ lang()?.title | transloco }}</span>
    </div>
  </button>

  <mat-divider class="my-2"></mat-divider>
  <!--    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>-->
  <!--<button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Settings</span>
    </button>-->
  <!--  <button
        mat-menu-item
        [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
        <span>Status</span>
    </button>
    <mat-divider class="my-2"></mat-divider>-->
  <button mat-menu-item [dir]="'_dir' | transloco" (click)="signOut()">
    <div class="min-w-full flex justify-center items-start gap-2">
      <mat-icon [svgIcon]="'heroicons_outline:logout'" class="m-0"></mat-icon>
      <span>{{ "log_out" | transloco }}</span>
    </div>
  </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
  <button mat-menu-item (click)="updateUserStatus('online')">
    <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
    <span>Online</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('away')">
    <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
    <span>Away</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('busy')">
    <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
    <span>Busy</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('not-visible')">
    <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
    <span>Invisible</span>
  </button>
</mat-menu>
