import { float } from "../float/float";

type Numeric = number | string;

export function getValueAfterTax({tax, value}: {tax: Numeric, value: Numeric}): number {
  return float(
    float(value) * ((100 + float(tax)) / 100)
  );
}

export function getValueBeforeTax({tax, value}: {tax: Numeric, value: Numeric}): number {
  return float(
    float(value) * (100 / (100 + float(tax)))
  );
}
