import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';
import {bookingDemo, customerDemo} from "./data";
import {servicesDemo, staffDemo, categoriesDemo} from "./data_services";
import {ProviderService} from "../../../models/api/providerService";
import {GStatic} from "../../../services/g-static";

@Injectable({
    providedIn: 'root'
})
export class BookingMockApi
{
    private _customer: any = customerDemo;
    private _invoice: any = bookingDemo;
    private _services: ProviderService[] = servicesDemo;
    private _staff: any = staffDemo;
    private _categories: any = categoriesDemo;


    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Categories - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/demo/find-customer/532754598', 500)
            .reply(() => {
                GStatic.log('api/demo/find-customer', [200,  cloneDeep(this._customer[0])])
                return [200, cloneDeep(this._customer[0])];
            });

        this._fuseMockApiService
            .onGet('api/demo/find-customer/532754599', 500)
            .reply(() => {
                GStatic.log('api/demo/find-customer', [200,  cloneDeep(this._customer[1])])
                return [200, cloneDeep(this._customer[1])];
            });
        this._fuseMockApiService
            .onGet('api/demo/find-customer/532754597', 500)
            .reply(() => {
                GStatic.log('api/demo/find-customer', [404,  []])
                return [404, []];
            });




        this._fuseMockApiService
            .onPost('api/demo/create-customer', 500)
            .reply(() => {
                GStatic.log('api/demo/find-customer', [200,  cloneDeep(this._customer[1])])
                return [200, cloneDeep(this._customer[1])];
            });


        this._fuseMockApiService
            .onGet('api/demo/services', 500)
            .reply(() => {
                GStatic.log('api/demo/services', [200,  cloneDeep(this._services)])
                return [200, cloneDeep(this._services)];
            });

        this._fuseMockApiService
            .onGet('api/demo/services-categories', 500)
            .reply(() => {
                GStatic.log('api/demo/services', [200,  cloneDeep(this._categories)])
                return [200, cloneDeep(this._categories)];
            });

        this._fuseMockApiService
            .onGet('api/demo/services-staff', 500)
            .reply(() => {
                GStatic.log('api/demo/services', [200,  cloneDeep(this._staff)])
                return [200, cloneDeep(this._staff)];
            });


        this._fuseMockApiService
            .onPost('api/demo/create-reservation', 500)
            .reply(() => {
                GStatic.log('api/demo/create-reservation', [200,  cloneDeep(this._invoice)])
                return [200, cloneDeep(this._invoice)];
            });



    }
}
