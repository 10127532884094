import { Injectable } from '@angular/core';
import { ApiResponse } from '../models/api/ApiResponse.Interface';
import { Customer, CustomerReservation } from '../models/api/customer';
import { environment } from '../../environments/environment';
import {
  BehaviorSubject,
  Observable,
  catchError,
  lastValueFrom,
  map,
  of,
  tap,
} from 'rxjs';
import { ProfileService } from './profile.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(
    private _profile: ProfileService,
    private _httpClient: HttpClient,
    private _toastr: ToastrService
  ) {}

  private cashedCustomerSubject = new BehaviorSubject<Customer>(null);
  foundCustomer$ = this.cashedCustomerSubject.asObservable();

  reset() {
    this.cashedCustomerSubject.next(null);
  }

  public findCustomerByMobileNumber = (
    mobile: string
  ): Observable<Customer> => {
    return this._httpClient
      .get<ApiResponse<Customer>>(
        environment.api_url + '/find-customer/' + mobile
      )
      .pipe(map((response) => response.data));
  };

  findCustomer(mobileNo: string) {
    if (+mobileNo === 0) {
      return of({} as Customer);
    }
    /*        if (mobileNo === this.cashedCustomerSubject.getValue()?.mobile)
                return this.cashedCustomerSubject.asObservable()
             else*/
    return this._httpClient
      .get<ApiResponse<Customer>>(
        environment.api_url + '/find-customer/' + mobileNo
      )
      .pipe(
        tap(async (response) => {
          if (response.status) {
            const oldBookings = await lastValueFrom(
              this._httpClient.get<ApiResponse<any[]>>(
                environment.api_url +
                  `/bookings?filter[user_id]=${response.data.id}`
              )
            );

            const sc = response.data;
            const sdf: CustomerReservation[] = oldBookings.data;

            if (sdf.length) {
              sc.reservations = sdf.sort(
                (a, b) =>
                  DateTime.fromISO(b.date).toMillis() -
                  DateTime.fromISO(a.date).toMillis()
              );
              this.cashedCustomerSubject.next(sc);
            }
          }
        }),
        map((e) => e.data),
        catchError((e) => {
          // caught.subscribe((e)=> GStatic.log('caught', e));
          this._toastr.warning(e.error.message);
          if (e.status === 400) {
            this.cashedCustomerSubject.next(null);
          }

          throw e;
        })
      );

    // return customer
  }

  createCustomer(customerDTO: {
    name: string;
    mobile: string;
    birth_date: string;
    email: string;
  }) {
    if (customerDTO.birth_date)
      customerDTO.birth_date =
        (customerDTO.birth_date as any)
          .toLocaleDateString('en-GB')
          .split('/')
          .reverse()
          .join('-') + '';
    return this._httpClient
      .post<ApiResponse<Customer>>(
        environment.api_url + '/create-customer',
        customerDTO
      )
      .pipe(
        tap((customer) => {
          this.cashedCustomerSubject.next(customer.data);
          // this.selectedCustomer = customer.data;
          // this.submitBody.user_id = this.selectedCustomer.id
        }),
        map((e) => e.data)
      );
  }

  updateCustomer(
    id: string,
    customerDTO: { name: string; birth_date: string; email: string }
  ) {
    return this._httpClient
      .post<ApiResponse<Customer>>(
        environment.api_url + '/edit-customer/' + id,
        customerDTO
      )
      .pipe(
        tap((customer) => {
          customer.data.reservations =
            this.cashedCustomerSubject.getValue().reservations;
          customer.data.wallet = this.cashedCustomerSubject.getValue().wallet;
          customer.data.bookings_count =
            this.cashedCustomerSubject.getValue().bookings_count;
          this.cashedCustomerSubject.next(customer.data);
          // this.selectedCustomer = customer.data;
          // this.submitBody.user_id = this.selectedCustomer.id
        }),
        map((e) => e.data)
      );
  }
}
