import {inject} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ReservationService} from 'app/modules/new-reservation/reservation.service';
import {ProfileService} from 'app/services/profile.service';
import {ReservationsService} from 'app/services/reservations.service';
import {ToastrService} from 'ngx-toastr';

export class BaseDialog {
  isLoading: boolean = false;

  fb = inject(FormBuilder);
  profileService = inject(ProfileService);
  reservationsService = inject(ReservationsService);
  reservationService = inject(ReservationService);
  toastrService = inject(ToastrService);
}