import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthUtils} from "./core/auth/auth.utils";
import {environment} from "../environments/environment";
import {TranslocoService} from "@ngneat/transloco";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

    constructor(private _tr: TranslocoService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let newReq = request.clone();
        if (request.url.includes(environment.api_base_url))
            newReq = request.clone({
                headers: request.headers.set('lang', this._tr.getActiveLang()).set('ver', environment.appVersion)
            });

        return next.handle(newReq);
    }
}
