<button
  *ngFor="let item of _list"
  type="button"
  mat-stroked-button
  class="rounded"
  [disabled]="disabled"
  [style.min-height]="height"
  [style.height]="height"
  [style.min-width]="width"
  [style.max-width]="maxWidth"
  [ngClass]="{
    'bg-primary text-on-primary border-primary':
      value?.toString() === item.value?.toString()
  }"
  (click)="changeValue(item)"
>
  <div class="whitespace-pre-wrap text-sm">
    {{ item.name | transloco }}
    <span *ngIf="debugMode"> - {{ item.value | json }}</span>
  </div>
</button>
<span *ngIf="debugMode"> - {{ value | json }}</span>
