import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spreadStrings',
})
export class SpreadStringsPipe implements PipeTransform {
  public transform(
    itemsList: any[],
    itemIndicator: string,
    symbol?: string,
    sliceNumber?: number
  ): string {
    const list: any[] = [...(itemsList || [])];

    if (sliceNumber) list.splice(-sliceNumber);

    return list.map((item: any) => item[itemIndicator]).join(symbol);
  }
}
