/* eslint-disable */

import {Customer} from "../../../models/api/customer";


export const bookingDemo = {
// id: '',
    code: 'BK00001',
// salon_id: '1',
// user_id: '7',
    endDate: null,
    date: '2021-12-23 17:00:00',
    notes: null,
    duration: null,
    cost: '50.00',
    vat: '7.50',
    fees_amount: '0',
    vat_percentage: '15.00',
    total: '50.00',
    status: '3',
    services_count: 8,
    is_feedback: '0',
    schedule: '0',
    reminder: '0',
    reminder_sent: '0',
    payment_method: '1',
    payment_retrieved: null,
    source: '2',
    type: '2',
    is_checkin: '0',
    arrived_at: null,
    is_checked_out: '0',
    voucher: null,
    voucher_amount: '0',
    offer_id: null,
    offer_amount: '0',
    specified_id: '1',
    staff_id: null,
    created_by: '7',
    updated_by: '1',
    created_at: '2021-12-24 01:59:58',
    updated_at: '2022-07-17 00:39:02',
    deleted_at: null
}


export const customerDemo: any[] = [
    {
        id: 1,
        customer_id: 'CST00001',
        name: 'Nishad Aliyar',
        phone: '9400947711',
        country_code: '+966',
        email: 'nishadaliyar@gmail.com',
        status: 'deactive',
        is_verified: '0',
        type: 'customer',
        is_new_user: 'no',
        dob: '2020-09-04',
        created_at: '2020-09-04 23:16:56',
        updated_at: '2020-12-02 22:02:50',
        deleted_at: '',
        language: 'en',
        need_notification: '1',
        reservations: [bookingDemo, bookingDemo, bookingDemo, bookingDemo, bookingDemo, bookingDemo, bookingDemo],
        balance: 50,

// 'verification_code': ''
// 'otp_generated_at': ''
// 'fcm_token': ''

    },
    {
        id: 1,
        customer_id: 'CST00001',
        name: 'Hazem Safwat',
        phone: '532754598',
        country_code: '+966',
        email: 'hazem@gmail.com',
        status: 'active',
        is_verified: '0',
        type: 'customer',
        is_new_user: 'no',
        dob: '1985-09-04',
        created_at: '2020-09-04 23:16:56',
        updated_at: '2020-12-02 22:02:50',
        deleted_at: '',
        language: 'en',
        need_notification: '1',
        reservations: [bookingDemo, bookingDemo, bookingDemo, bookingDemo, bookingDemo, bookingDemo, bookingDemo],
        balance: 150,
// 'verification_code': ''
// 'otp_generated_at': ''
// 'fcm_token': ''

    }
]










































