import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';
import { GStatic } from './g-static';

@Injectable({
  providedIn: 'root',
})
export class DebugViewService {
  isProduction = environment.production;
  data: { key: string; val: any }[] = [];
  htmlData: string[] = [];
  intervalRef: any;
  oldHtml = '';

  hidden = true;
  size = 1;

  bodyElem: HTMLDivElement;

  constructor(private _route: ActivatedRoute, private _router: Router) {
    if (!this.isProduction) {
      const elem = document.createElement('div');
      elem.className = 'my-debugger';
      document.body.appendChild(elem);

      const headElem = document.createElement('div');
      headElem.className = 'head';
      this.bodyElem = document.createElement('div');
      this.bodyElem.className = 'body';
      elem.appendChild(headElem);
      elem.appendChild(this.bodyElem);

      const btn1 = document.createElement('button');
      btn1.classList.add('close');
      btn1.id = 'close_btn';
      btn1.innerText = this.hidden ? '^' : 'x';
      btn1.onclick = (event) => this.hide();
      headElem.appendChild(btn1);

      const btn2 = document.createElement('button');
      btn2.classList.add('close');
      btn2.innerText = 'reset';
      btn2.onclick = (event) => this.clear();
      headElem.appendChild(btn2);

      this.addDebug('isProduction', this.isProduction);

      // this.addDebug('key', {key: 'key', val: {test: 'key', test2: 'v hello p'}});
      this.intervalRef = setInterval(() => {
        const dd = this.buildHtml();
        if (dd !== this.oldHtml) {
          if (dd === '<div dir="ltr"></div>') {
            this.hide(true);
          }
          this.oldHtml = this.buildHtml();
          this.bodyElem.innerHTML = this.oldHtml;
        }
      }, 100);

      this._router.events.subscribe((value) => {
        if (value instanceof NavigationEnd) {
          // GStatic.logImp('this._router.events value', value, value instanceof NavigationEnd);
          this.clear();
        }
      });

      this.hide(this.hidden);
    }
  }

  addDebug(key: string, val: any): void {
    const found = this.data.find((d) => d.key === key);
    if (found) {
      found.val = val;
    } else {
      this.data.push({ key, val });
    }
  }

  hide(val: boolean = null): void {
    if (val === null) {
      this.hidden = !this.hidden;
    } else {
      this.hidden = val;
    }
    if (this.hidden) {
      this.bodyElem.classList.add('hidden');
    } else {
      this.bodyElem.classList.remove('hidden');
    }
    const btn2 = document.getElementById('close_btn');
    btn2.innerText = this.hidden ? '^' : 'x';
  }

  clear(): void {
    this.data = [];
    this.htmlData = [];
    this.oldHtml = '';
  }

  private buildHtml(): string {
    let data1 = '<div dir="ltr">';
    this.data.forEach((v) => {
      if (typeof v.val === 'object') {
        data1 += `<p><span class="d-key">${
          v.key
        } :</span><br> <span  class="d-val"><pre style="font-size: smaller">${JSON.stringify(
          v.val,
          null,
          4
        )}</pre></span> </p>`;
      } else {
        data1 += `<p><span class="d-key">${v.key} :</span> <span class="d-val"> ${v.val} </span> </p>`;
      }
    });
    data1 += '</div>';

    const anyDebugContents = document.getElementsByClassName('debug');
    if (anyDebugContents.length > 0) {
      for (let i = 0; i < anyDebugContents.length; i++) {
        data1 +=
          '<div class="mt-1 p-2" style="background-color: #1a293b55;" dir="ltr">' +
          anyDebugContents[i].innerHTML +
          '</div>';
        anyDebugContents[i].classList.add('hidden');
      }
    }
    return data1;
  }
}
