<section class="bg-white p-3">
  <div class="flex flex-col gap-y-2">
    <!-- Subtotal -->
    <div class="flex items-center justify-between">
      <div>{{ 'subtotal' | transloco }}</div>
      <div>{{ invoice.subtotal }}</div>
    </div>

    <!-- Fees -->
    <div
      *ngIf="invoice.fees > 0"
      class="flex items-center justify-between text-red-500"
    >
      <div>{{ 'DELIVERY_FEES' | transloco }}</div>
      <div>- {{ invoice.fees }}</div>
    </div>

    <!-- Discount -->
    <div *ngIf="invoice.discount.value > 0">
      <div
        *ngIf="isPercentageDiscount(invoice.discount.type)"
        class="flex items-center justify-between text-red-500"
      >
        <div>
          {{ 'DISCOUNT' | transloco: { percentage: invoice.discount.value } }}
        </div>
        <div>- {{ invoice.discount.value }}%</div>
      </div>
      <div
        *ngIf="!isPercentageDiscount(invoice.discount.type)"
        class="flex items-center justify-between text-red-500"
      >
        <div>{{ 'booking.Discount' | transloco }}</div>
        <div>- {{ invoice.discount.value }}</div>
      </div>
    </div>

    <hr class="my-4" />

    <!-- Total -->
    <div class="flex items-center justify-between font-bold">
      <div>{{ 'booking.Total' | transloco }}</div>
      <div>{{ invoice.total }}</div>
    </div>

    <!-- Upfront underpayment -->
    <div *ngIf="invoice.upfront > 0" class="flex flex-col gap-y-2">
      <div class="flex items-center justify-between text-red-500">
        <div>{{ 'booking.upfront' | transloco }}</div>
        <div>- {{ invoice.upfront }}</div>
      </div>

      <div class="flex items-center justify-between font-bold text-blue-500">
        <div>{{ 'booking.Remaining amount' | transloco }}</div>
        <div>{{ invoice.totalWithUnderpayment }}</div>
      </div>
    </div>
  </div>
</section>
