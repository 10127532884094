import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DiscountType, Invoice } from "app/shared/utilities/invoicing/invoicing";

@Component({
  selector: "pink-invoice-view",
  templateUrl: "./template.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceViewComponent {
  @Input()
  invoice: Invoice;

  get discountTypes(): typeof DiscountType {
    return DiscountType;
  }

  isPercentageDiscount(type: number): boolean {
    return type === this.discountTypes.Percentage;
  }
}
