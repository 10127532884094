import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";


export interface LayoutHeader {
    type?: 'title' | 'btns'
    title: string,
    icon?: string,
    btns?: HeaderBtn[],

    actions?: any[]
}

export interface HeaderBtn {
    title: string,
    routerLink: string,
    css?: string,
    routerLinkActiveCss?: string
}

@Injectable({
    providedIn: 'root'
})
export class MyLayoutService {

    constructor() {
    }

   private headerSubject = new BehaviorSubject<LayoutHeader>({} as LayoutHeader);
    header$ = this.headerSubject.asObservable();
    get header(){
        return this.headerSubject.getValue();
    }
    set header(val){
        this.headerSubject.next(val);
    }
  /*  header: LayoutHeader = {
        title: 'تسجيل حجز جديد',
        icon: 'search',
        actions: [{}]
    };*/


    setHeader(snapshot: LayoutHeader) {
        this.header = snapshot
        this.headerSubject.next(snapshot);
    }
    setHeaderPartial(snapshot: LayoutHeader) {
        // this.header = {...this.header, ...snapshot}
        this.headerSubject.next({...this.header, ...snapshot});
    }
}
