<div
  class="absolute left-0 top-0 h-screen w-screen flex justify-center items-center z-99999"
>
  <div
    class="w-full h-full flex justify-center items-center bg-black opacity-80"
  >
    <span class="text-white sm:text-2xl text-lg w-1/2 text-center z-99999">{{
      "invalidScreenMsg" | transloco
    }}</span>
  </div>
</div>
