import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public constructor(
    private readonly _authService: AuthService,
    private readonly _translateService: TranslocoService
  ) {}

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request object

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this._authService.accessToken}`,
      Accept: 'application/json',
      ['Content-Type']: 'application/json',
      ['client-key']: 123456789,
      ['client-name']: 'Cashier',
      lang: this._translateService.getActiveLang(),
      version: environment.appVersion,
    });

    // Response
    return next.handle(req.clone({ headers })).pipe(
      catchError((error) => {
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Sign out
          this._authService.signOut();

          // Reload the app
          //   location.reload();
        }

        return throwError(() => error);
      })
    );
  }
}
