<div
  mat-dialog-title
  class="py-3 flex flex-row items-center border-b border-b-gray-200"
>
  <h1 class="font-bold text-xl">
    {{'cancel_var_reservation' | transloco : {var:data?.title} }}
  </h1>
</div>

<mat-dialog-content>
  <ng-container [formGroup]="cancelForm">
    <mat-form-field class="w-full mt-4">
      <mat-label>{{'RESERVATION.CANCELLATION_REASON' | transloco}}</mat-label>
      <textarea
        matInput
        formControlName="reason"
        [placeholder]="'RESERVATION.CANCELLATION_REASON' | transloco"
        class="h-full max-h-full"
        maxlength="1024"
      ></textarea>
    </mat-form-field>

    <div
      *ngIf="hasPartiallyPaid"
      class="flex flex-col justify-center items-stretch gap-2"
    >
      <label class="text-base text-black font-medium"
        >{{'recovery_method' | transloco}} *</label
      >

      <app-selective-btns
        [list]="PayList"
        formControlName="refundMethod"
        class="flex-wrap justify-center"
        max-width="'42px'"
      ></app-selective-btns>
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions
  class="bg-gray-100 px-4 py-3 flex border-t justify-end gap-2"
>
  <button (click)="close()" mat-stroked-button>{{'Cancel' | transloco}}</button>
  <button (click)="doCancelReservation()" mat-raised-button color="primary">
    {{'booking.Continue' | transloco}}
  </button>
</mat-dialog-actions>
