import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { StaffMember } from '@pink/types';
import { EditService } from 'app/modules/new-reservation/s10-edit/services/edit.service';
import { Booking } from 'app/modules/new-reservation/s10-edit/utils/interfaces/edit-interfaces.interface';

@Component({
  selector: 'pink-staff-list',
  templateUrl: './stuff-list.component.html',
  styleUrls: ['./stuff-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaffListComponent {
  private readonly _editService = inject(EditService);

  @Input() public staff: StaffMember[] = [];

  @Input() public currentBooking: Booking | null = null;

  public get isAllSelected(): boolean {
    return this._editService?.selectedStaffMembers.getValue()?.length === 0;
  }

  public isSelectedMember = (memberId?: number): boolean => {
    return !!this._editService?.selectedStaffMembers
      .getValue()
      ?.find((staff: StaffMember) => staff?.id === memberId);
  };

  public onSelectMember = (member?: StaffMember): void => {
    if (!!member) {
      const selectedMembers: StaffMember[] = [
        ...this._editService?.selectedStaffMembers.getValue(),
      ];

      const memberIndex: number = selectedMembers.findIndex(
        (m) => m?.id === member.id
      );

      if (memberIndex >= 0) selectedMembers.splice(memberIndex, 1);
      else selectedMembers.push(member);

      this._editService.selectedStaffMembers.next(selectedMembers);

      return;
    }

    this._editService.selectedStaffMembers.next([]);
  };
}
