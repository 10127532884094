import { isNil, isNumber, isString, replace, round, toNumber } from "lodash";

/**
 * Safely parses values into floats/integers.
 *
 * @param {unknown} value
 * @returns {number}
 */
export const float = (value: unknown): number => {
  if (isNumber(value)) return round(value, 2);

  if (isNil(value)) return round(0, 2);

  if (isString(value)) {
    const SAFE_INTEGER = replace(value, /[^0-9.]/g, "");
    return round(toNumber(SAFE_INTEGER), 2);
  }
};
