import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {ThemePalette} from "@angular/material/core";

@Component({
    selector: 'app-btn',
    templateUrl: './btn.component.html',
    styleUrls: ['./btn.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BtnComponent {
    @Input() btnMat: 'raised' | 'stroked' | 'flat' | 'none' = "raised";
    @Input() type: 'button' | 'submit' | 'reset';
    @Input() color: ThemePalette;
    @Input() class: string;
    @Input() disabled: boolean;
    @Input() disableRipple: boolean;
    // @Output() click = new EventEmitter<MouseEvent>();


}
