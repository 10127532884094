/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';

platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
                        console.log('env', environment.env);
                        console.log('ver', environment.appVersion);
                        console.log('base', environment.api_base_url);
