import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationHelperService {
  public readonly selectedDay: BehaviorSubject<string | null> =
    new BehaviorSubject(null);

  public readonly selectedDay$: Observable<string | null> =
    this.selectedDay.asObservable();
}
