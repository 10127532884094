import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseSplashScreenModule } from '@fuse/services/splash-screen';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { UserComponent } from 'app/layout/common/user/user.component';
import { LanguageComponent } from 'app/shared/components/language/language.component';

@NgModule({
  declarations: [UserComponent],
  imports: [
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    TranslocoCoreModule,
    LanguageComponent,
    FuseSplashScreenModule,
  ],
  exports: [UserComponent],
})
export class UserModule {}
