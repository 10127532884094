import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InvalidScreenService {
  public readonly screenIsInvalid: Subject<boolean> = new Subject();

  public readonly screenIsInvalid$: Observable<boolean> =
    this.screenIsInvalid.asObservable();
}
