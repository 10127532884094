import { Pipe, PipeTransform } from '@angular/core';
import { IdName } from 'app/shared/utilities/interfaces/id-name.interface';

@Pipe({ name: 'shortStaffName' })
export class ShortStaffNamePipe implements PipeTransform {
  public transform(staffList: IdName[]): any {
    return staffList.length
      ? staffList?.map((worker: IdName) => {
          if (worker?.name.length) {
            const containsSplit: boolean = worker?.name?.includes('|');

            const names: string[] | string = containsSplit
              ? worker?.name?.split('|')
              : worker?.name;

            const arabicName: string = containsSplit
              ? (names as string[])
                  ?.find((name: string) => /[\u0600-\u06FF]/.test(name))
                  ?.trim()
              : worker?.name;

            const englishName: string = containsSplit
              ? (names as string[])
                  ?.find((name: string) => !/[\u0600-\u06FF]/.test(name))
                  ?.trim()
              : worker?.name;

            return {
              id: worker?.id,
              arChar: arabicName[0] || '',
              arName: arabicName || '',
              enChar: englishName? englishName[0]?.toUpperCase() || '' : '',
              enName: englishName?.toUpperCase() || '',
            };
          } else {
            return {
              id: undefined,
              arChar: undefined,
              arName: undefined,
              enChar: undefined,
              enName: undefined,
            };
          }
        })
      : [];
  }
}
