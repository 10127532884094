import {
    ApplicationRef,
    ComponentFactoryResolver,
    Injectable,
} from '@angular/core';

import {BehaviorSubject} from "rxjs";
import {ReceiptComponent} from "../modules/new-reservation/components/receipt/receipt.component";
import {ReservationDetails} from "../models/api/reservation.interface";
import CryptoJS from "crypto-js";
import {GStatic} from "./g-static";
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PrintInvoiceService {


    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private appRef: ApplicationRef,

    ) {
    }


    private printComponent(data: any): string {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ReceiptComponent);
        const componentRef = componentFactory.create(this.appRef.injector);
        componentRef.instance.data = data;
        this.appRef.attachView(componentRef.hostView);
        const html = componentRef.location.nativeElement.innerHTML;
        componentRef.destroy();
        return html;
    }


    printInvoice1(data: ReservationDetails ) {
        const subject = new BehaviorSubject(false);

        // const divToPrint = document.getElementById('printArea');
        const divToPrint = this.printComponent({});
        let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no`;
        const newWin = window.open(undefined, 'print', params);
        newWin.document.write(divToPrint);
        /*   newWin.document.close();
         newWin.print();

  /*   newWin.onafterprint = () => newWin.close();
         newWin.closed
         window.onfocus = () => {
             if (newWin.closed) {
                 GStatic.log('newWin.closed');
                 subject.next(true);
                 subject.complete();
             }
         }*/
        setTimeout(() => {

        }, 10000)
        // newWin.close();

        return subject.asObservable();
    }

    CryptoJSAesDecrypt(passphrase,encrypted_json_string){

        const obj_json = JSON.parse(encrypted_json_string);

        const encrypted = obj_json.ciphertext;
        const salt = CryptoJS.enc.Hex.parse(obj_json.salt);
        const iv = CryptoJS.enc.Hex.parse(obj_json.iv);

        const key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});


        const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

   encrypt_decrypt(string: string, action: string = 'encrypt'): string {
    // const encryptMethod: string = "AES-256-CBC";
    const secretKey: string = 'AA74CDCC2BBRT935136HH7B63C27'; // user defined private key
    const secretIv: string = '5fgf5HJ5g27'; // user defined secret key
    const key: any = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex);
    const iv: any = CryptoJS.SHA256(secretIv).toString(CryptoJS.enc.Hex).substr(0, 16);

    if (action === 'encrypt') {
        let cipher = CryptoJS.AES.encrypt(string, key, { iv: iv });
        let encrypted_: string = cipher?.toString();
        return encrypted_;
    } else if (action === 'decrypt') {
        let cipher = CryptoJS.AES.decrypt(string, key, { iv: iv });
        let decrypted_: string = cipher.toString(CryptoJS.enc.Utf8);
        return decrypted_;
    }
}

    decrypt2(string: string): string {

        // const string = "aW5wdXQgdGVzdA=="; // "input test" in base64
        const encryptMethod = "AES-256-CBC";
        const secretKey = 'AA74CDCC2BBRT935136HH7B63C27'; // user defined private key
        const secretIv = '5fgf5HJ5g27'; // user defined secret key
        const key = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex);
        const iv = CryptoJS.SHA256(secretIv).toString(CryptoJS.enc.Hex).substr(0, 16);




        const decrypted = CryptoJS.AES.decrypt(string, key, { iv: iv });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }







    printInvoice(data: ReservationDetails ) {
        const subject = new BehaviorSubject(false);

        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no`;
        const url = [
            environment.api_base_url,
            "/ar/provider/booking/invoice-cachier/",
            data.invoice_url
        ].join("");

   /*     const sad = this.encrypt_decrypt('emtCdDhzZ283MkFTRG5iYW9iVElxZz09', 'encrypt')
       const cc = this.encrypt_decrypt('emtCdDhzZ283MkFTRG5iYW9iVElxZz09', 'decrypt' )
       const c234c = this.decrypt2('emtCdDhzZ283MkFTRG5iYW9iVElxZz09' )
       const c234c2 = this.decrypt2('yomssJy6GDA+Dg8wSghmnZLDRJP2MLsVSVh76cWJdVQ=' )
        // const divToPrint = document.getElementById('printArea');*/

        const newWin = window.open(url, "print", params);
        newWin.document.close();
         // newWin.print();

    newWin.onafterprint = () => newWin.close();
         newWin.closed
         window.onfocus = () => {
             if (newWin.closed) {
                 GStatic.log('newWin.closed');
                 subject.next(true);
                 subject.complete();
             }
         }
        setTimeout(() => {

        }, 10000)
        // newWin.close();

        return subject.asObservable();
    }
}
