import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toNum'
})
export class ToNumPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return !isNaN(Number(value)) ? Number(value): value;
  }

}
