import {Component, Input, OnInit} from '@angular/core';
import {ReservationDetails} from "../../../../models/api/reservation.interface";
import {ReservationsService} from "../../../../services/reservations.service";
import {GStatic} from "../../../../services/g-static";


@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit{

    constructor(private _reservations: ReservationsService,) {
    }
    @Input() data: ReservationDetails;

    ngOnInit(): void {
        if(!this.data){
            this._reservations.getReservationDetails('10824').subscribe((val) => {

                GStatic.log('getReservationDetails', val)

            })

        }

    }
}
