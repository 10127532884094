<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'" appBreakpointObserver></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Centered -->
<centered-layout
  *ngIf="layout === 'centered'"
  appBreakpointObserver
></centered-layout>

<!-- Enterprise -->
<enterprise-layout
  *ngIf="layout === 'enterprise'"
  appBreakpointObserver
></enterprise-layout>

<!-- Material -->
<material-layout
  *ngIf="layout === 'material'"
  appBreakpointObserver
></material-layout>

<!-- Modern -->
<modern-layout
  *ngIf="layout === 'modern'"
  appBreakpointObserver
></modern-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Classic -->
<classic-layout
  *ngIf="layout === 'classic'"
  appBreakpointObserver
></classic-layout>

<!-- Classy -->
<classy-layout
  *ngIf="layout === 'classy'"
  appBreakpointObserver
></classy-layout>

<!-- Compact -->
<compact-layout
  *ngIf="layout === 'compact'"
  appBreakpointObserver
></compact-layout>

<!-- Dense -->
<dense-layout *ngIf="layout === 'dense'" appBreakpointObserver></dense-layout>

<!-- Futuristic -->
<futuristic-layout
  *ngIf="layout === 'futuristic'"
  appBreakpointObserver
></futuristic-layout>

<!-- Thin -->
<thin-layout *ngIf="layout === 'thin'" appBreakpointObserver></thin-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!--<settings appBreakpointObserver></settings>-->
