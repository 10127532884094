import {Injectable, WritableSignal, signal} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, forkJoin, Observable, shareReplay, tap} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Category, CatService, Specification} from '../shared/api-models';
import {environment} from '../../environments/environment';
import {DateTime} from 'luxon';
import {
  ProviderOffer,
  ProviderService,
  ServiceCategory,
  ServiceStaff,
} from '../models/api/providerService';
import {ApiResponse} from '../models/api/ApiResponse.Interface';
import {WorkingHours} from '../models/api/workingHours.interface';
import {Lookups, LookupsArr} from '../models/api/lookups.interface';
import {NextPageType} from 'app/modules/new-reservation/s1-user/s1-user.component';

export enum ReservationType {
  Salon = 1,
  Home = 2,
}

export interface GroupServices {
  salonServices: {
    categoryDetails: Category;
    subServices: CatService[];
  }[];
  homeServices: {
    categoryDetails: Category;
    subServices: CatService[];
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public constructor(
    private _httpClient: HttpClient,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
  }

  private updates: { name: string; date: DateTime }[] = [
    {name: 'int', date: DateTime.now()},
  ];

  public lookup: WritableSignal<Lookups | null> = signal(null);

  private anyUpdateSubject = new BehaviorSubject<string>('init');

  public previousReservationType = new BehaviorSubject<NextPageType>('salon');
  anyUpdate$ = this.anyUpdateSubject.asObservable();

  firstLoaded: boolean = false;
  isLoaded: boolean = false;
  salonId: number;
  isSkinAnSoul: boolean = false;

  offers: ProviderOffer[] = [];
  services: ProviderService[] = [];
  staff: ServiceStaff[];
  serviceCategories: ServiceCategory[];

  specifications: Specification[];
  lookups: Lookups;
  lookupsArr: LookupsArr;

  private _workingHours: WorkingHours;

  public get workingHours(): WorkingHours {
    return this._workingHours;
  }

  public hasVat: boolean = false;
  vatPercentage: number = 0;

  fetch(): Observable<any> {
    this.firstLoaded = false;
    return forkJoin([
      this.fetchCategories(),
      this.fetchStaff(),
      this.fetchWorkingHours(),
      this.fetchLookUps(),
    ]).pipe(
      tap((s) => {
        this.firstLoaded = true;
        this.isLoaded = true;
      })
    );
  }

  /**
   * @param {ReservationType} specifiedId The filter criteria.
   */
  fetchServices(specifiedId?: ReservationType) {
    const params = specifiedId ? {specified_id: specifiedId} : {};
    return this._httpClient
      .get<ApiResponse<ProviderService[]>>(environment.api_url + '/services', {
        params,
      })
      .pipe(
        tap((response) => {
          this.services = response.data;
          this.services.forEach((e) => {
            if (typeof e.amount === 'string')
              e.amount = e.amount?.replaceAll(',', '');
            if (typeof e.amountBefore === 'string')
              e.amountBefore = e.amountBefore?.replaceAll(',', '');
          });
          this.setUpdate('fetchServices');
        })
      );
  }

  /**
   * @param {ReservationType} specifiedId The filter criteria.
   */
  fetchOffers(specifiedId?: ReservationType) {
    const params = specifiedId ? {specified_id: specifiedId} : {};
    return this._httpClient
      .get<ApiResponse<ProviderOffer[]>>(environment.api_url + '/offers', {
        params,
      })
      .pipe(
        tap((response) => {
          this.offers = response.data;
          this.offers.forEach((e) => {
            if (typeof e.amount === 'string')
              e.amount = e.amount?.replaceAll(',', '');
            if (typeof e.amountBefore === 'string')
              e.amountBefore = e.amountBefore?.replaceAll(',', '');
          });
          this.setUpdate('fetchOffers');
        })
      );
  }

  fetchCategories() {
    return this._httpClient
      .get<ApiResponse<ServiceCategory[]>>(environment.api_url + '/categories')
      .pipe(
        tap((response) => {
          this.serviceCategories = response.data;
          this.setUpdate('fetchCategories');
        })
      );
  }

  fetchStaff() {
    return this._httpClient
      .get<ApiResponse<ServiceStaff[]>>(environment.api_url + '/staffs')
      .pipe(
        tap((response) => {
          this.staff = response.data.map((staff: any) => {
            //todo
            return {
              id: staff.id,
              name_lang: {
                en: staff.name.split('|')[0]?.trim(),
                ar: staff.name.split(' | ')[1]?.trim(),
              },
              // name_en: staff.name.split('|')[0]?.trim(),
              name: (
                staff.name.split('|')[1]?.trim() ||
                staff.name.split('|')[0]?.trim()
              )?.split(' ')[0],
            };
          });

          this.setUpdate('fetchStaff');
        }),
        shareReplay(1)
      );
  }

  getStaffMemberById(id: number): ServiceStaff {
    const filteredStaffMembers = this.staff.filter(
      (member) => member.id === id
    );
    if (filteredStaffMembers.length > 0) {
      return filteredStaffMembers[0];
    } else {
      return null;
    }
  }

  fetchWorkingHours() {
    return this._httpClient
      .get<ApiResponse<WorkingHours>>(environment.api_url + '/appointments')
      .pipe(
        tap((response) => {
          this._workingHours = response.data;
          this.setUpdate('fetchWorkingHours');
        })
      );
  }

  fetchLookUps() {
    return this._httpClient
      .get<ApiResponse<any>>(environment.api_url + '/bookings/create')
      .pipe(
        tap((response) => {
          this.lookup.set(response.data);
          this.lookups = response.data;
          this.lookupsArr = {} as LookupsArr;
          this.salonId = response.data.salon;
          this.isSkinAnSoul = response.data.salon_webiste.includes('skin-&-soul-skincare-studio');
          console.log('isSkinAnSoul', this.isSkinAnSoul);
          Object.entries(response.data).forEach((v) => {
            this.lookupsArr[v[0]] = Object.entries(v[1]).map((e) => ({
              name: e[1],
              value: e[0],
            }));
          });
          // GStatic.logImp('reservationLookUps', response.data);
          /*          if (response.status) {
                                      this.paymentMethods = response.data.payment_methods
                                      this.paymentMethods2 = response.data.payment
                                      this.reservationStatus = response.data.staus;
                                      this.paymentTypes = response.data.payment_type;
                                      this.discountTypes = response.data.discount;
                                  }*/
          this.hasVat = response.data.has_vat;
          this.vatPercentage = Number(response.data.vat_percent);
        })
      );
  }

  private setUpdate(theUpdateName: string) {
    this.updates.push({name: theUpdateName, date: DateTime.now()});
    this.anyUpdateSubject.next(theUpdateName);
  }

  /**
   * Given an array of staff IDs, this method will return their full interface.
   *
   * @param {number[]} staffIds
   * @returns {ServiceStaff[]}
   */
  getStaffById(staffIds: number[] = []): ServiceStaff[] {
    staffIds = staffIds.map((id) => +id);

    return this.staff.filter((s) => staffIds.includes(+s.id));
  }

  salonHasVat(): boolean {
    return this.hasVat;
  }
}
