<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<invalid-screen-size *ngIf="screenIsInvalid$ | async" />
<!-- Navigation -->
<fuse-vertical-navigation
  class="dark print:hidden"
  style="background-color: #3a1728 !important"
  [appearance]="'compact'"
  [position]="lang === 'ar' ? 'right' : 'left'"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.compact"
  [opened]="!isScreenSmall"
>
  <ng-container fuseVerticalNavigationContentHeader>
    <div class="flex items-center justify-center h-20 mt-3 mb-4">
      <img
        class="w-10"
        src="assets/images/logo/pink-logo_light.png"
        alt="Logo image"
      />
    </div>
  </ng-container>

  <ng-container fuseVerticalNavigationFooter>
    <div class="flex justify-center items-center gap-1 h-20 mt-3">
      <user />

      <!-- <language /> -->
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->

  <!-- Content -->

  <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
  <router-outlet *ngIf="true"></router-outlet>

  <!-- Footer -->
  <!--<div
        class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>
--></div>

<!-- Quick chat -->
