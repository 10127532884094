import {ProviderService, ServiceCategory} from "../../../models/api/providerService";


export const servicesDemo: any[] = [
    {
        "id": 22,
        "services_time": 15,
        "amount": "50.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "One color | medium",
        "name_ar": "صبغة لون واحد | متوسط"
    },
    {
        "id": 23,
        "services_time": 15,
        "amount": "60.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,2,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "One color | long",
        "name_ar": "صبغة لون واحد | طويل"
    },
    {
        "id": 68,
        "services_time": 15,
        "amount": "50.00",
        "amountBefore": null,
        "category_id": 3,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Deep clean facial cleansing",
        "name_ar": "تنظيف البشرة العميق"
    },
    {
        "id": 69,
        "services_time": 15,
        "amount": "50.00",
        "amountBefore": null,
        "category_id": 4,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "makeup",
        "name_ar": "المكياج"
    },
    {
        "id": 70,
        "services_time": 15,
        "amount": "50.00",
        "amountBefore": null,
        "category_id": 4,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Eyeliner",
        "name_ar": "ايلاينر"
    },
    {
        "id": 25,
        "services_time": 30,
        "amount": "999.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Hair color with ammonia | medium",
        "name_ar": "صبغة لون واحد مع الأمونيا| متوسط"
    },
    {
        "id": 3,
        "services_time": 30,
        "amount": "300.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Nail polish for hands",
        "name_ar": "إضافة لون لليدين"
    },
    {
        "id": 312,
        "services_time": 10,
        "amount": "55.00",
        "amountBefore": null,
        "category_id": 13,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Childrens Cuts  ",
        "name_ar": "قص شعر للأطفال"
    },
    {
        "id": 487,
        "services_time": 15,
        "amount": "90.00",
        "amountBefore": null,
        "category_id": 3,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Classic Facial",
        "name_ar": "فيشل كلاسيك"
    },
    {
        "id": 5,
        "services_time": 5,
        "amount": "500.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Paraffin hands",
        "name_ar": "برافين لليدين"
    },
    {
        "id": 26,
        "services_time": 5,
        "amount": "55.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Hair color with ammonia | long",
        "name_ar": "صبغة لون واحد مع الأمونيا | طويل"
    },
    {
        "id": 488,
        "services_time": 15,
        "amount": "90.00",
        "amountBefore": null,
        "category_id": 3,
        "staff": [1,2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Facial Special Angel",
        "name_ar": "فيشل سبيشل انجل"
    },
    {
        "id": 38,
        "services_time": 5,
        "amount": "30.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "High & low light  | long",
        "name_ar": "صبغة خصل لايت لولايت | طويل"
    },
    {
        "id": 1,
        "services_time": 1,
        "amount": "100.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Classic manicure",
        "name_ar": "مناكير كلاسيك"
    },
    {
        "id": 2,
        "services_time": 10,
        "amount": "200.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Classic pedicure",
        "name_ar": "بديكير كلاسيك"
    },
    {
        "id": 4,
        "services_time": 35,
        "amount": "400.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Nail polish for feets",
        "name_ar": "إضافة لون للرجلين"
    },
    {
        "id": 7,
        "services_time": 10,
        "amount": "600.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Gel extension",
        "name_ar": "لون الجِل"
    },
    {
        "id": 6,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Paraffin feet",
        "name_ar": "برافين للقدمين"
    },
    {
        "id": 8,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Gel polish removal",
        "name_ar": "إزالة لون الجِل"
    },
    {
        "id": 9,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Hands shillac",
        "name_ar": "لون شيلاك"
    },
    {
        "id": 10,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Ombre color",
        "name_ar": "لون اومبريه"
    },
    {
        "id": 13,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "french manicure for feets",
        "name_ar": "مناكير فرنسي للرجلين"
    },
    {
        "id": 14,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "french manicure for hands and feets",
        "name_ar": "مناكير فرنسي لليدين والرجلين"
    },
    {
        "id": 18,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Nails extension | feets",
        "name_ar": "تركيب أظافر مؤقتة للرجلين"
    },
    {
        "id": 19,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Nail art",
        "name_ar": "رسم لأظفر واحد"
    },
    {
        "id": 15,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Acrylic Extension Full Set | Hands",
        "name_ar": "تركيب أظافر أكريلك لليدين"
    },
    {
        "id": 16,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Acrylic Extension Full Set | Feets",
        "name_ar": "تركيب أظافر أكريلك للرجلين"
    },
    {
        "id": 364,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Classic pedicure",
        "name_ar": "بديكير كلاسيك"
    },
    {
        "id": 365,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Classic Manicure & pedicure",
        "name_ar": "بديكير و مناكير كلاسيك"
    },
    {
        "id": 368,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "poly gel Ombre",
        "name_ar": "بولي جل مع أومبرية"
    },
    {
        "id": 369,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Nails Color",
        "name_ar": "لون أظافر"
    },
    {
        "id": 489,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Acrylic or gel nails",
        "name_ar": "أظافر اكريليك أو جل"
    },
    {
        "id": 490,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "French gel nail color",
        "name_ar": "لون أظافر جيل فرنش"
    },
    {
        "id": 940,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Ombré for hands",
        "name_ar": "اومبريه لليدين"
    },
    {
        "id": 810,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Spa manicure and pedicure VS",
        "name_ar": "بديكير ومناكير سبا VS"
    },
    {
        "id": 798,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Acrylic gel ceramic",
        "name_ar": "اكريلك جل سيراميك"
    },
    {
        "id": 797,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Ceramic gel nails",
        "name_ar": "أظافر جل سيراميك"
    },
    {
        "id": 788,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Hand gel manicure removal with pedicure",
        "name_ar": "إزالة مناكير جل لليدين مع بديكير"
    },
    {
        "id": 787,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "French nail color",
        "name_ar": "لون أظافر فرنسي"
    },
    {
        "id": 742,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Pedicure treatment  | for the feet",
        "name_ar": "بديكير علاجي | للقدمين"
    },
    {
        "id": 741,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Pedicure treatment | for hands",
        "name_ar": "بديكير علاجي | لليدين"
    },
    {
        "id": 740,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Hand + foot pedicure treatment",
        "name_ar": "بديكير علاجي لليدين + للقدمين"
    },
    {
        "id": 715,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "VIP gel pedicure",
        "name_ar": "بديكير جل في اي بي"
    },
    {
        "id": 714,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "VIP pedicure",
        "name_ar": "بديكير في اي بي علاجي"
    },
    {
        "id": 336,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Manicure & pedicure for Kids",
        "name_ar": "بديكير  مناكير الأطفال"
    },
    {
        "id": 363,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Classic manicure",
        "name_ar": "مناكير كلاسيك"
    },
    {
        "id": 366,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "file nails with color",
        "name_ar": "برد ولون"
    },
    {
        "id": 367,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "poly gel",
        "name_ar": "بولي جل"
    },
    {
        "id": 388,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Add color for the hands",
        "name_ar": "إضافة لون لليدين"
    },
    {
        "id": 491,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Light gel layer to protect nails",
        "name_ar": "طبقة جل خفيفة لحماية الأظافر"
    },
    {
        "id": 492,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Cut and file fingernails",
        "name_ar": "قص وبرد أظافر اليدين"
    },
    {
        "id": 493,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Cut and file toenails",
        "name_ar": "قص وبرد أظافر القدمين"
    },
    {
        "id": 494,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Plain chrome powder",
        "name_ar": "كروم باودر عادي"
    },
    {
        "id": 495,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Paraffin Angel for Hands Treatment",
        "name_ar": "علاج بارافين انجل هاندز لليدين"
    },
    {
        "id": 496,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Paraffin Angel Hands for Foot Treatment",
        "name_ar": "علاج بارافين انجل هاندز للقدمين"
    },
    {
        "id": 498,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Gold treatment for feet",
        "name_ar": "علاج الذهب للقدمين"
    },
    {
        "id": 497,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Gold treatment for hands",
        "name_ar": "علاج الذهب لليدين"
    },
    {
        "id": 499,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Angel Hands Set for Hand and Feet Care",
        "name_ar": "مجموعة انجل هاندز للعناية باليدين والقدمين"
    },
    {
        "id": 500,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 1,
        "staff": [1,2,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Detox set for hands and feet",
        "name_ar": "مجموعة الديتوكس للعناية باليدين والقدمين"
    },
    {
        "id": 21,
        "services_time": 10,
        "amount": "5.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,2],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "One color | short",
        "name_ar": "صبغة لون واحد | قصير"
    },
    {
        "id": 24,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,2,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Hair color with ammonia | short",
        "name_ar": "صبغة لون واحد مع الأمونيا | قصير"
    },
    {
        "id": 30,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,2,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Hair color with bleaching | short",
        "name_ar": "صبغة لون واحد مع سحب لون | قصير"
    },
    {
        "id": 29,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,2,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Hair color with ammonia-free | long",
        "name_ar": "صبغة لون واحد خالية من الامونيا | طويل"
    },
    {
        "id": 33,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,2,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Hair color without bleaching | short",
        "name_ar": "صبغة لون واحد بدون سحب لون | قصير"
    },
    {
        "id": 34,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,2,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Hair color without bleaching | medium",
        "name_ar": "صبغة لون واحد بدون سحب لون | متوسط"
    },
    {
        "id": 37,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "High & low light  | medium",
        "name_ar": "صبغة خصل لايت لولايت | متوسط"
    },
    {
        "id": 36,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "High & low light  | short",
        "name_ar": "صبغة خصل لايت لولايت | قصير"
    },
    {
        "id": 39,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "MAJIREL Hair color | short",
        "name_ar": "الوان الماجاريل | قصير"
    },
    {
        "id": 42,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Toner | short",
        "name_ar": "تفتيح لون | قصير"
    },
    {
        "id": 41,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "MAJIREL Hair color | long",
        "name_ar": "الوان الماجاريل | طويل"
    },
    {
        "id": 40,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "MAJIREL Hair color | medium",
        "name_ar": "الوان الماجاريل | متوسط"
    },
    {
        "id": 43,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [1,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Toner | medium",
        "name_ar": "تفتيح لون | متوسط"
    },
    {
        "id": 44,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Toner | long",
        "name_ar": "تفتيح لون | طويل"
    },
    {
        "id": 55,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Ombre with color | short",
        "name_ar": "أومبري | قصير"
    },
    {
        "id": 56,
        "services_time": 15,
        "amount": "11.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Ombre with color | medium",
        "name_ar": "أومبري | متوسط"
    },
    {
        "id": 57,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Ombre with color | long",
        "name_ar": "أومبري | طويل"
    },
    {
        "id": 58,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Highlight | short",
        "name_ar": "هاي لايت | قصير"
    },
    {
        "id": 59,
        "services_time": 1,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Highlight|  medium",
        "name_ar": "هاي لايت | متوسط"
    },
    {
        "id": 60,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Highlight | long",
        "name_ar": "هاي لايت | طويل"
    },
    {
        "id": 61,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "INOA color |  short",
        "name_ar": "الوان الانوا | قصير"
    },
    {
        "id": 62,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "INOA color | medium",
        "name_ar": "الوان الانوا | متوسط"
    },
    {
        "id": 63,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3,4],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "INOA color | long",
        "name_ar": "الوان الانوا | طويل"
    },
    {
        "id": 389,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Rensaj",
        "name_ar": "رنساج"
    },
    {
        "id": 390,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Roots tincture",
        "name_ar": "صبغة الجذور"
    },
    {
        "id": 406,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Organic Roots | Short",
        "name_ar": "صبغة عضوي | قصير"
    },
    {
        "id": 915,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Hair dye | short",
        "name_ar": "صبغة شعر | قصير"
    },
    {
        "id": 412,
        "services_time": 15,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Organic Ombre | Short",
        "name_ar": "أومبري عضوي  | قصير"
    },
    {
        "id": 413,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 2,
        "staff": [2,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Organic Ombre | Medium",
        "name_ar": "أومبري عضوي | متوسط"
    },
    {
        "id": 351,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 3,
        "staff": [3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Classic facial cleansing",
        "name_ar": "تنظيف بشرة كلاسيك"
    },
    {
        "id": 352,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 3,
        "staff": [1],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Deep clean facial cleansing",
        "name_ar": "تنظيف البشرة العميق"
    },
    {
        "id": 709,
        "services_time": 5,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 3,
        "staff": [1,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Mini facial",
        "name_ar": "ميني فيشل"
    },
    {
        "id": 688,
        "services_time": 10,
        "amount": "1.00",
        "amountBefore": null,
        "category_id": 3,
        "staff": [1,3],
        "description": 'صبغة لون واحد | متوسط صبغة لون واحد | متوسط صبغة لون واحد | متوسط ',
        "name_en": "Gold mask",
        "name_ar": "ماسك الذهب"
    },
]


export const staffDemo = [
    {
        "id": 1,
        "name_en": "Fatma",
        "name_ar": "فاطمة"
    },
    {
        "id": 2,
        "name_en": "Rawan",
        "name_ar": "روان"
    },
    {
        "id": 3,
        "name_en": "Halima",
        "name_ar": "حليما"
    },
    {
        "id": 4,
        "name_en": "Reem",
        "name_ar": "ريم"
    },

]


export const categoriesDemo: any[] = [
    {
        "id": 1,
        "code": null,
        "icon": "ty9EMw8whGQkINrnBgFqC4uRFv05K75ubUIKePqz.jpg",
        "name_en": "Nail care",
        "name_ar": "العناية باليدين والقدمين"
    },
    {
        "id": 2,
        "code": null,
        "icon": "U90TkW9w7qkn1MpYIX7XwHDrOxtRNvQ5H1RqQtuM.jpg",
        "name_en": "Hair coloring",
        "name_ar": "صبغات الشعر"
    },
    {
        "id": 3,
        "code": null,
        "icon": "vDm5eVhc3KX1em8dy6m1b7J7avee6hVs2antyhoX.jpg",
        "name_en": "Skin care",
        "name_ar": "العناية بالبشرة"
    },
    {
        "id": 4,
        "code": null,
        "icon": "Toe1f0XBJ2klHjVJYEf75vgkfSZselZEWFsuNNkN.jpg",
        "name_en": "Beauty",
        "name_ar": "التجميل"
    },
    {
        "id": 13,
        "code": null,
        "icon": "57lHX3Beqv0aIPqbiD9fLsSed4LtzQ6jGamRIKvC.jpg",
        "name_en": "Little lady",
        "name_ar": "خدمات الأطفال"
    }
]

export const serviceqqsDemo = {

    specifications: {
        1: "Salon",
        27: "Home Service"
    },
    categories: [
        {
            id: 1,
            code: null,
            specified_id: "1,2",
            icon: "ty9EMw8whGQkINrnBgFqC4uRFv05K75ubUIKePqz.jpg",
            pin: 0,
            status: 1,
            private: 0,
            created_by: 1,
            updated_by: 2,
            created_at: "2021-12-14T11:45:46.000000Z",
            updated_at: "2022-01-04T19:22:43.000000Z",
            deleted_at: null,
            lang: {
                en: {
                    id: 1,
                    category_id: 1,
                    name: "Nail care",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2022-01-04T19:22:43.000000Z"
                },
                ar: {
                    id: 2,
                    category_id: 1,
                    name: "العناية باليدين والقدمين",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2022-01-04T19:22:43.000000Z"
                }
            }
        },
        {
            id: 2,
            code: null,
            specified_id: "1,2",
            icon: "U90TkW9w7qkn1MpYIX7XwHDrOxtRNvQ5H1RqQtuM.jpg",
            pin: 0,
            status: 1,
            private: 0,
            created_by: 1,
            updated_by: 2,
            created_at: "2021-12-14T11:45:46.000000Z",
            updated_at: "2022-01-04T19:23:08.000000Z",
            deleted_at: null,
            lang: {
                en: {
                    id: 3,
                    category_id: 2,
                    name: "Hair coloring",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2022-01-03T09:38:07.000000Z"
                },
                ar: {
                    id: 4,
                    category_id: 2,
                    name: "صبغات الشعر",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2022-01-03T09:38:07.000000Z"
                }
            }
        },
        {
            id: 3,
            code: null,
            specified_id: "1,2",
            icon: "vDm5eVhc3KX1em8dy6m1b7J7avee6hVs2antyhoX.jpg",
            pin: 0,
            status: 1,
            private: 0,
            created_by: 1,
            updated_by: 2,
            created_at: "2021-12-14T11:45:46.000000Z",
            updated_at: "2022-01-04T19:23:37.000000Z",
            deleted_at: null,
            lang: {
                en: {
                    id: 5,
                    category_id: 3,
                    name: "Skin care",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2022-01-04T19:23:37.000000Z"
                },
                ar: {
                    id: 6,
                    category_id: 3,
                    name: "العناية بالبشرة",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2022-01-04T19:23:37.000000Z"
                }
            }
        },
        {
            id: 4,
            code: null,
            specified_id: "1,2",
            icon: "Toe1f0XBJ2klHjVJYEf75vgkfSZselZEWFsuNNkN.jpg",
            pin: 0,
            status: 1,
            private: 0,
            created_by: 1,
            updated_by: 2,
            created_at: "2021-12-14T11:45:46.000000Z",
            updated_at: "2022-01-04T19:23:57.000000Z",
            deleted_at: null,
            lang: {
                en: {
                    id: 7,
                    category_id: 4,
                    name: "Beauty",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2022-01-04T19:23:57.000000Z"
                },
                ar: {
                    id: 8,
                    category_id: 4,
                    name: "التجميل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2022-01-04T19:23:57.000000Z"
                }
            }
        },
        {
            id: 13,
            code: null,
            specified_id: "1,2",
            icon: "57lHX3Beqv0aIPqbiD9fLsSed4LtzQ6jGamRIKvC.jpg",
            pin: 0,
            status: 1,
            private: 0,
            created_by: 1,
            updated_by: 2,
            created_at: "2021-12-14T11:45:47.000000Z",
            updated_at: "2022-01-04T19:22:30.000000Z",
            deleted_at: null,
            lang: {
                en: {
                    id: 25,
                    category_id: 13,
                    name: "Little lady",
                    lang: "en",
                    created_at: "2021-12-14T11:45:47.000000Z",
                    updated_at: "2022-01-03T09:37:37.000000Z"
                },
                ar: {
                    id: 26,
                    category_id: 13,
                    name: "خدمات الأطفال",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:47.000000Z",
                    updated_at: "2022-01-03T09:37:37.000000Z"
                }
            }
        }
    ],
    services: [
        {
            id: 5,
            salon_id: 1,
            specified_id: 1,
            service_id: 22,
            services_time_id: 4,
            amount: "50.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2021-12-23T17:30:12.000000Z",
            updated_at: "2022-12-01T10:20:49.000000Z",
            deleted_at: null,
            service: {
                id: 22,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 43,
                    service_id: 22,
                    name: "One color | medium",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 44,
                    service_id: 22,
                    name: "صبغة لون واحد | متوسط",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 6,
            salon_id: 1,
            specified_id: 1,
            service_id: 23,
            services_time_id: 4,
            amount: "60.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2021-12-23T17:30:12.000000Z",
            updated_at: "2022-12-01T10:20:49.000000Z",
            deleted_at: null,
            service: {
                id: 23,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 45,
                    service_id: 23,
                    name: "One color | long",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 46,
                    service_id: 23,
                    name: "صبغة لون واحد | طويل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 7,
            salon_id: 1,
            specified_id: 1,
            service_id: 68,
            services_time_id: 4,
            amount: "50.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2021-12-23T17:30:12.000000Z",
            updated_at: "2022-12-01T10:20:49.000000Z",
            deleted_at: null,
            service: {
                id: 68,
                category_id: 3,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 135,
                    service_id: 68,
                    name: "Deep clean facial cleansing",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 136,
                    service_id: 68,
                    name: "تنظيف البشرة العميق",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 8,
            salon_id: 1,
            specified_id: 1,
            service_id: 69,
            services_time_id: 4,
            amount: "50.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2021-12-23T17:30:12.000000Z",
            updated_at: "2022-12-01T10:20:49.000000Z",
            deleted_at: null,
            service: {
                id: 69,
                category_id: 4,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 137,
                    service_id: 69,
                    name: "makeup",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 138,
                    service_id: 69,
                    name: "المكياج",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 9,
            salon_id: 1,
            specified_id: 1,
            service_id: 70,
            services_time_id: 4,
            amount: "50.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2021-12-23T17:30:12.000000Z",
            updated_at: "2022-12-01T10:20:49.000000Z",
            deleted_at: null,
            service: {
                id: 70,
                category_id: 4,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 139,
                    service_id: 70,
                    name: "Eyeliner",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 140,
                    service_id: 70,
                    name: "ايلاينر",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 1109,
            salon_id: 1,
            specified_id: 1,
            service_id: 25,
            services_time_id: 7,
            amount: "999.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-06-19T13:58:48.000000Z",
            updated_at: "2022-12-01T10:20:49.000000Z",
            deleted_at: null,
            service: {
                id: 25,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 7,
                time: 30,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:39:08.000000Z",
                updated_at: "2021-12-23T17:39:08.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 49,
                    service_id: 25,
                    name: "Hair color with ammonia | medium",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 50,
                    service_id: 25,
                    name: "صبغة لون واحد مع الأمونيا| متوسط",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 1239,
            salon_id: 1,
            specified_id: 1,
            service_id: 3,
            services_time_id: 7,
            amount: "300.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-07-07T16:11:19.000000Z",
            updated_at: "2022-12-06T08:28:48.000000Z",
            deleted_at: null,
            service: {
                id: 3,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 7,
                time: 30,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:39:08.000000Z",
                updated_at: "2021-12-23T17:39:08.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 5,
                    service_id: 3,
                    name: "Nail polish for hands",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 6,
                    service_id: 3,
                    name: "إضافة لون لليدين",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 1309,
            salon_id: 1,
            specified_id: 1,
            service_id: 312,
            services_time_id: 3,
            amount: "55.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-08-21T23:20:39.000000Z",
            updated_at: "2022-12-01T10:20:49.000000Z",
            deleted_at: null,
            service: {
                id: 312,
                category_id: 13,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:47.000000Z",
                updated_at: "2021-12-14T11:45:47.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 623,
                    service_id: 312,
                    name: "Childrens Cuts  ",
                    lang: "en",
                    created_at: "2021-12-14T11:45:47.000000Z",
                    updated_at: "2021-12-14T11:45:47.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 624,
                    service_id: 312,
                    name: "قص شعر للأطفال",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:47.000000Z",
                    updated_at: "2021-12-14T11:45:47.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 1310,
            salon_id: 1,
            specified_id: 1,
            service_id: 487,
            services_time_id: 4,
            amount: "90.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-08-22T10:31:49.000000Z",
            updated_at: "2022-12-01T10:20:49.000000Z",
            deleted_at: null,
            service: {
                id: 487,
                category_id: 3,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:36:51.000000Z",
                updated_at: "2022-04-16T21:36:51.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 973,
                    service_id: 487,
                    name: "Classic Facial",
                    lang: "en",
                    created_at: "2022-04-16T21:36:51.000000Z",
                    updated_at: "2022-04-16T21:36:51.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 974,
                    service_id: 487,
                    name: "فيشل كلاسيك",
                    lang: "ar",
                    created_at: "2022-04-16T21:36:51.000000Z",
                    updated_at: "2022-04-16T21:36:51.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 1384,
            salon_id: 1,
            specified_id: 1,
            service_id: 5,
            services_time_id: 2,
            amount: "500.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-10-11T12:41:01.000000Z",
            updated_at: "2022-12-06T08:28:48.000000Z",
            deleted_at: null,
            service: {
                id: 5,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 9,
                    service_id: 5,
                    name: "Paraffin hands",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 10,
                    service_id: 5,
                    name: "برافين لليدين",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 1385,
            salon_id: 1,
            specified_id: 1,
            service_id: 26,
            services_time_id: 2,
            amount: "55.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-10-12T06:10:54.000000Z",
            updated_at: "2022-12-01T10:20:49.000000Z",
            deleted_at: null,
            service: {
                id: 26,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 51,
                    service_id: 26,
                    name: "Hair color with ammonia | long",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 52,
                    service_id: 26,
                    name: "صبغة لون واحد مع الأمونيا | طويل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 1467,
            salon_id: 1,
            specified_id: 1,
            service_id: 488,
            services_time_id: 4,
            amount: "90.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-10-23T07:51:52.000000Z",
            updated_at: "2022-12-01T10:20:49.000000Z",
            deleted_at: null,
            service: {
                id: 488,
                category_id: 3,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:37:20.000000Z",
                updated_at: "2022-04-16T21:37:20.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 975,
                    service_id: 488,
                    name: "Facial Special Angel",
                    lang: "en",
                    created_at: "2022-04-16T21:37:20.000000Z",
                    updated_at: "2022-04-16T21:37:20.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 976,
                    service_id: 488,
                    name: "فيشل سبيشل انجل",
                    lang: "ar",
                    created_at: "2022-04-16T21:37:20.000000Z",
                    updated_at: "2022-04-16T21:37:20.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 1778,
            salon_id: 1,
            specified_id: 1,
            service_id: 38,
            services_time_id: 2,
            amount: "30.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-11-19T13:53:14.000000Z",
            updated_at: "2022-12-01T10:20:49.000000Z",
            deleted_at: null,
            service: {
                id: 38,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 75,
                    service_id: 38,
                    name: "High & low light  | long",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 76,
                    service_id: 38,
                    name: "صبغة خصل لايت لولايت | طويل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2209,
            salon_id: 1,
            specified_id: 1,
            service_id: 1,
            services_time_id: 1,
            amount: "100.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-11-28T12:03:47.000000Z",
            updated_at: "2022-12-06T08:31:24.000000Z",
            deleted_at: null,
            service: {
                id: 1,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1,
                    service_id: 1,
                    name: "Classic manicure",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 2,
                    service_id: 1,
                    name: "مناكير كلاسيك",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2210,
            salon_id: 1,
            specified_id: 1,
            service_id: 2,
            services_time_id: 3,
            amount: "200.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-11-28T12:03:47.000000Z",
            updated_at: "2022-12-06T08:31:24.000000Z",
            deleted_at: null,
            service: {
                id: 2,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 3,
                    service_id: 2,
                    name: "Classic pedicure",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 4,
                    service_id: 2,
                    name: "بديكير كلاسيك",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2457,
            salon_id: 1,
            specified_id: 1,
            service_id: 4,
            services_time_id: 8,
            amount: "400.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-01T10:20:32.000000Z",
            updated_at: "2022-12-06T08:28:48.000000Z",
            deleted_at: null,
            service: {
                id: 4,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 8,
                time: 35,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:39:32.000000Z",
                updated_at: "2021-12-23T17:39:32.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 7,
                    service_id: 4,
                    name: "Nail polish for feets",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 8,
                    service_id: 4,
                    name: "إضافة لون للرجلين",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2458,
            salon_id: 1,
            specified_id: 1,
            service_id: 7,
            services_time_id: 3,
            amount: "600.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-01T10:20:32.000000Z",
            updated_at: "2022-12-06T08:28:48.000000Z",
            deleted_at: null,
            service: {
                id: 7,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 13,
                    service_id: 7,
                    name: "Gel extension",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 14,
                    service_id: 7,
                    name: "لون الجِل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2613,
            salon_id: 1,
            specified_id: 1,
            service_id: 6,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T10:57:40.000000Z",
            updated_at: "2022-12-11T10:57:40.000000Z",
            deleted_at: null,
            service: {
                id: 6,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 11,
                    service_id: 6,
                    name: "Paraffin feet",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 12,
                    service_id: 6,
                    name: "برافين للقدمين",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2614,
            salon_id: 1,
            specified_id: 1,
            service_id: 8,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T10:57:40.000000Z",
            updated_at: "2022-12-11T10:57:40.000000Z",
            deleted_at: null,
            service: {
                id: 8,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 15,
                    service_id: 8,
                    name: "Gel polish removal",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 16,
                    service_id: 8,
                    name: "إزالة لون الجِل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2615,
            salon_id: 1,
            specified_id: 1,
            service_id: 9,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:13:48.000000Z",
            updated_at: "2022-12-11T12:13:48.000000Z",
            deleted_at: null,
            service: {
                id: 9,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 17,
                    service_id: 9,
                    name: "Hands shillac",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 18,
                    service_id: 9,
                    name: "لون شيلاك",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2616,
            salon_id: 1,
            specified_id: 1,
            service_id: 10,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:13:48.000000Z",
            updated_at: "2022-12-11T12:13:48.000000Z",
            deleted_at: null,
            service: {
                id: 10,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 19,
                    service_id: 10,
                    name: "Ombre color",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 20,
                    service_id: 10,
                    name: "لون اومبريه",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2617,
            salon_id: 1,
            specified_id: 1,
            service_id: 13,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:14:02.000000Z",
            updated_at: "2022-12-11T12:14:02.000000Z",
            deleted_at: null,
            service: {
                id: 13,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 25,
                    service_id: 13,
                    name: "french manicure for feets",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 26,
                    service_id: 13,
                    name: "مناكير فرنسي للرجلين",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2618,
            salon_id: 1,
            specified_id: 1,
            service_id: 14,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:14:02.000000Z",
            updated_at: "2022-12-11T12:14:02.000000Z",
            deleted_at: null,
            service: {
                id: 14,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 27,
                    service_id: 14,
                    name: "french manicure for hands and feets",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 28,
                    service_id: 14,
                    name: "مناكير فرنسي لليدين والرجلين",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2619,
            salon_id: 1,
            specified_id: 1,
            service_id: 18,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:14:22.000000Z",
            updated_at: "2022-12-11T12:14:22.000000Z",
            deleted_at: null,
            service: {
                id: 18,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 35,
                    service_id: 18,
                    name: "Nails extension | feets",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 36,
                    service_id: 18,
                    name: "تركيب أظافر مؤقتة للرجلين",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2620,
            salon_id: 1,
            specified_id: 1,
            service_id: 19,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:14:22.000000Z",
            updated_at: "2022-12-11T12:14:22.000000Z",
            deleted_at: null,
            service: {
                id: 19,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 37,
                    service_id: 19,
                    name: "Nail art",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 38,
                    service_id: 19,
                    name: "رسم لأظفر واحد",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2621,
            salon_id: 1,
            specified_id: 1,
            service_id: 15,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:15:06.000000Z",
            updated_at: "2022-12-11T12:15:06.000000Z",
            deleted_at: null,
            service: {
                id: 15,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 29,
                    service_id: 15,
                    name: "Acrylic Extension Full Set | Hands",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 30,
                    service_id: 15,
                    name: "تركيب أظافر أكريلك لليدين",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2622,
            salon_id: 1,
            specified_id: 1,
            service_id: 16,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:15:06.000000Z",
            updated_at: "2022-12-11T12:15:06.000000Z",
            deleted_at: null,
            service: {
                id: 16,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 31,
                    service_id: 16,
                    name: "Acrylic Extension Full Set | Feets",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 32,
                    service_id: 16,
                    name: "تركيب أظافر أكريلك للرجلين",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2623,
            salon_id: 1,
            specified_id: 1,
            service_id: 364,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:15:19.000000Z",
            updated_at: "2022-12-11T12:15:19.000000Z",
            deleted_at: null,
            service: {
                id: 364,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-29T13:10:46.000000Z",
                updated_at: "2021-12-29T13:10:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 727,
                    service_id: 364,
                    name: "Classic pedicure",
                    lang: "en",
                    created_at: "2021-12-29T13:10:46.000000Z",
                    updated_at: "2021-12-29T13:10:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 728,
                    service_id: 364,
                    name: "بديكير كلاسيك",
                    lang: "ar",
                    created_at: "2021-12-29T13:10:46.000000Z",
                    updated_at: "2021-12-29T13:10:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2624,
            salon_id: 1,
            specified_id: 1,
            service_id: 365,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:15:19.000000Z",
            updated_at: "2022-12-11T12:15:19.000000Z",
            deleted_at: null,
            service: {
                id: 365,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-29T13:14:15.000000Z",
                updated_at: "2021-12-29T13:14:15.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 729,
                    service_id: 365,
                    name: "Classic Manicure & pedicure",
                    lang: "en",
                    created_at: "2021-12-29T13:14:15.000000Z",
                    updated_at: "2021-12-29T13:14:15.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 730,
                    service_id: 365,
                    name: "بديكير و مناكير كلاسيك",
                    lang: "ar",
                    created_at: "2021-12-29T13:14:15.000000Z",
                    updated_at: "2021-12-29T13:14:15.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2625,
            salon_id: 1,
            specified_id: 1,
            service_id: 368,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:15:31.000000Z",
            updated_at: "2022-12-11T12:15:31.000000Z",
            deleted_at: null,
            service: {
                id: 368,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-29T13:27:36.000000Z",
                updated_at: "2021-12-29T13:27:36.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 735,
                    service_id: 368,
                    name: "poly gel Ombre",
                    lang: "en",
                    created_at: "2021-12-29T13:27:36.000000Z",
                    updated_at: "2021-12-29T13:27:36.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 736,
                    service_id: 368,
                    name: "بولي جل مع أومبرية",
                    lang: "ar",
                    created_at: "2021-12-29T13:27:36.000000Z",
                    updated_at: "2021-12-29T13:27:36.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2626,
            salon_id: 1,
            specified_id: 1,
            service_id: 369,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:15:31.000000Z",
            updated_at: "2022-12-11T12:15:31.000000Z",
            deleted_at: null,
            service: {
                id: 369,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-29T13:30:04.000000Z",
                updated_at: "2021-12-29T13:30:04.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 737,
                    service_id: 369,
                    name: "Nails Color",
                    lang: "en",
                    created_at: "2021-12-29T13:30:04.000000Z",
                    updated_at: "2021-12-29T13:30:04.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 738,
                    service_id: 369,
                    name: "لون أظافر",
                    lang: "ar",
                    created_at: "2021-12-29T13:30:04.000000Z",
                    updated_at: "2021-12-29T13:30:04.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2627,
            salon_id: 1,
            specified_id: 1,
            service_id: 489,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:15:55.000000Z",
            updated_at: "2022-12-11T12:15:55.000000Z",
            deleted_at: null,
            service: {
                id: 489,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:38:07.000000Z",
                updated_at: "2022-04-16T21:38:07.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 977,
                    service_id: 489,
                    name: "Acrylic or gel nails",
                    lang: "en",
                    created_at: "2022-04-16T21:38:07.000000Z",
                    updated_at: "2022-04-16T21:38:07.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 978,
                    service_id: 489,
                    name: "أظافر اكريليك أو جل",
                    lang: "ar",
                    created_at: "2022-04-16T21:38:07.000000Z",
                    updated_at: "2022-04-16T21:38:07.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2628,
            salon_id: 1,
            specified_id: 1,
            service_id: 490,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:15:55.000000Z",
            updated_at: "2022-12-11T12:15:55.000000Z",
            deleted_at: null,
            service: {
                id: 490,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:38:43.000000Z",
                updated_at: "2022-04-16T21:38:43.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 979,
                    service_id: 490,
                    name: "French gel nail color",
                    lang: "en",
                    created_at: "2022-04-16T21:38:43.000000Z",
                    updated_at: "2022-04-16T21:38:43.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 980,
                    service_id: 490,
                    name: "لون أظافر جيل فرنش",
                    lang: "ar",
                    created_at: "2022-04-16T21:38:43.000000Z",
                    updated_at: "2022-04-16T21:38:43.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2629,
            salon_id: 1,
            specified_id: 1,
            service_id: 940,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:16:14.000000Z",
            updated_at: "2022-12-11T12:16:14.000000Z",
            deleted_at: null,
            service: {
                id: 940,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-08T09:21:19.000000Z",
                updated_at: "2022-12-08T09:21:19.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1879,
                    service_id: 940,
                    name: "Ombré for hands",
                    lang: "en",
                    created_at: "2022-12-08T09:21:19.000000Z",
                    updated_at: "2022-12-08T09:21:19.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1880,
                    service_id: 940,
                    name: "اومبريه لليدين",
                    lang: "ar",
                    created_at: "2022-12-08T09:21:19.000000Z",
                    updated_at: "2022-12-08T09:21:19.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2630,
            salon_id: 1,
            specified_id: 1,
            service_id: 810,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:16:14.000000Z",
            updated_at: "2022-12-11T12:16:14.000000Z",
            deleted_at: null,
            service: {
                id: 810,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-23T07:41:24.000000Z",
                updated_at: "2022-11-23T07:41:32.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1619,
                    service_id: 810,
                    name: "Spa manicure and pedicure VS",
                    lang: "en",
                    created_at: "2022-11-23T07:41:24.000000Z",
                    updated_at: "2022-11-23T07:41:24.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1620,
                    service_id: 810,
                    name: "بديكير ومناكير سبا VS",
                    lang: "ar",
                    created_at: "2022-11-23T07:41:24.000000Z",
                    updated_at: "2022-11-23T07:41:24.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2631,
            salon_id: 1,
            specified_id: 1,
            service_id: 798,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:16:31.000000Z",
            updated_at: "2022-12-11T12:16:31.000000Z",
            deleted_at: null,
            service: {
                id: 798,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-20T08:28:12.000000Z",
                updated_at: "2022-11-20T09:47:29.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1595,
                    service_id: 798,
                    name: "Acrylic gel ceramic",
                    lang: "en",
                    created_at: "2022-11-20T08:28:12.000000Z",
                    updated_at: "2022-11-20T08:28:12.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1596,
                    service_id: 798,
                    name: "اكريلك جل سيراميك",
                    lang: "ar",
                    created_at: "2022-11-20T08:28:12.000000Z",
                    updated_at: "2022-11-20T08:28:12.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2632,
            salon_id: 1,
            specified_id: 1,
            service_id: 797,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:16:31.000000Z",
            updated_at: "2022-12-11T12:16:31.000000Z",
            deleted_at: null,
            service: {
                id: 797,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-20T08:27:26.000000Z",
                updated_at: "2022-11-20T09:47:47.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1593,
                    service_id: 797,
                    name: "Ceramic gel nails",
                    lang: "en",
                    created_at: "2022-11-20T08:27:26.000000Z",
                    updated_at: "2022-11-20T08:27:26.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1594,
                    service_id: 797,
                    name: "أظافر جل سيراميك",
                    lang: "ar",
                    created_at: "2022-11-20T08:27:26.000000Z",
                    updated_at: "2022-11-20T08:27:26.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2633,
            salon_id: 1,
            specified_id: 1,
            service_id: 788,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:16:51.000000Z",
            updated_at: "2022-12-11T12:16:51.000000Z",
            deleted_at: null,
            service: {
                id: 788,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-19T15:41:59.000000Z",
                updated_at: "2022-11-20T09:53:04.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1575,
                    service_id: 788,
                    name: "Hand gel manicure removal with pedicure",
                    lang: "en",
                    created_at: "2022-11-19T15:41:59.000000Z",
                    updated_at: "2022-11-19T15:41:59.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1576,
                    service_id: 788,
                    name: "إزالة مناكير جل لليدين مع بديكير",
                    lang: "ar",
                    created_at: "2022-11-19T15:41:59.000000Z",
                    updated_at: "2022-11-19T15:41:59.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2634,
            salon_id: 1,
            specified_id: 1,
            service_id: 787,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:16:51.000000Z",
            updated_at: "2022-12-11T12:16:51.000000Z",
            deleted_at: null,
            service: {
                id: 787,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-19T15:41:03.000000Z",
                updated_at: "2022-11-20T09:50:04.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1573,
                    service_id: 787,
                    name: "French nail color",
                    lang: "en",
                    created_at: "2022-11-19T15:41:03.000000Z",
                    updated_at: "2022-11-19T15:41:03.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1574,
                    service_id: 787,
                    name: "لون أظافر فرنسي",
                    lang: "ar",
                    created_at: "2022-11-19T15:41:03.000000Z",
                    updated_at: "2022-11-19T15:41:03.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2635,
            salon_id: 1,
            specified_id: 1,
            service_id: 742,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:17:22.000000Z",
            updated_at: "2022-12-11T12:17:22.000000Z",
            deleted_at: null,
            service: {
                id: 742,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-16T10:15:21.000000Z",
                updated_at: "2022-11-16T10:15:35.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1483,
                    service_id: 742,
                    name: "Pedicure treatment  | for the feet",
                    lang: "en",
                    created_at: "2022-11-16T10:15:21.000000Z",
                    updated_at: "2022-11-16T10:15:21.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1484,
                    service_id: 742,
                    name: "بديكير علاجي | للقدمين",
                    lang: "ar",
                    created_at: "2022-11-16T10:15:21.000000Z",
                    updated_at: "2022-11-16T10:15:21.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2636,
            salon_id: 1,
            specified_id: 1,
            service_id: 741,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:17:22.000000Z",
            updated_at: "2022-12-11T12:17:22.000000Z",
            deleted_at: null,
            service: {
                id: 741,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-16T10:14:39.000000Z",
                updated_at: "2022-11-16T10:14:39.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1481,
                    service_id: 741,
                    name: "Pedicure treatment | for hands",
                    lang: "en",
                    created_at: "2022-11-16T10:14:39.000000Z",
                    updated_at: "2022-11-16T10:14:39.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1482,
                    service_id: 741,
                    name: "بديكير علاجي | لليدين",
                    lang: "ar",
                    created_at: "2022-11-16T10:14:39.000000Z",
                    updated_at: "2022-11-16T10:14:39.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2637,
            salon_id: 1,
            specified_id: 1,
            service_id: 740,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:17:22.000000Z",
            updated_at: "2022-12-11T12:17:22.000000Z",
            deleted_at: null,
            service: {
                id: 740,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-16T10:14:00.000000Z",
                updated_at: "2022-11-16T10:14:00.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1479,
                    service_id: 740,
                    name: "Hand + foot pedicure treatment",
                    lang: "en",
                    created_at: "2022-11-16T10:14:00.000000Z",
                    updated_at: "2022-11-16T10:14:00.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1480,
                    service_id: 740,
                    name: "بديكير علاجي لليدين + للقدمين",
                    lang: "ar",
                    created_at: "2022-11-16T10:14:00.000000Z",
                    updated_at: "2022-11-16T10:14:00.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2638,
            salon_id: 1,
            specified_id: 1,
            service_id: 715,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:17:49.000000Z",
            updated_at: "2022-12-11T12:17:49.000000Z",
            deleted_at: null,
            service: {
                id: 715,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-16T08:22:24.000000Z",
                updated_at: "2022-11-16T08:22:24.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1429,
                    service_id: 715,
                    name: "VIP gel pedicure",
                    lang: "en",
                    created_at: "2022-11-16T08:22:24.000000Z",
                    updated_at: "2022-11-16T08:22:24.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1430,
                    service_id: 715,
                    name: "بديكير جل في اي بي",
                    lang: "ar",
                    created_at: "2022-11-16T08:22:24.000000Z",
                    updated_at: "2022-11-16T08:22:24.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2639,
            salon_id: 1,
            specified_id: 1,
            service_id: 714,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:17:49.000000Z",
            updated_at: "2022-12-11T12:17:49.000000Z",
            deleted_at: null,
            service: {
                id: 714,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-16T08:21:46.000000Z",
                updated_at: "2022-11-16T08:21:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1427,
                    service_id: 714,
                    name: "VIP pedicure",
                    lang: "en",
                    created_at: "2022-11-16T08:21:46.000000Z",
                    updated_at: "2022-11-16T08:21:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1428,
                    service_id: 714,
                    name: "بديكير في اي بي علاجي",
                    lang: "ar",
                    created_at: "2022-11-16T08:21:46.000000Z",
                    updated_at: "2022-11-16T08:21:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2640,
            salon_id: 1,
            specified_id: 1,
            service_id: 336,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:18:20.000000Z",
            updated_at: "2022-12-11T12:18:20.000000Z",
            deleted_at: null,
            service: {
                id: 336,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-28T11:38:39.000000Z",
                updated_at: "2021-12-28T11:38:39.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 671,
                    service_id: 336,
                    name: "Manicure & pedicure for Kids",
                    lang: "en",
                    created_at: "2021-12-28T11:38:39.000000Z",
                    updated_at: "2021-12-28T11:38:39.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 672,
                    service_id: 336,
                    name: "بديكير  مناكير الأطفال",
                    lang: "ar",
                    created_at: "2021-12-28T11:38:39.000000Z",
                    updated_at: "2021-12-28T11:38:39.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2641,
            salon_id: 1,
            specified_id: 1,
            service_id: 363,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:18:20.000000Z",
            updated_at: "2022-12-11T12:18:20.000000Z",
            deleted_at: null,
            service: {
                id: 363,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-29T13:10:15.000000Z",
                updated_at: "2021-12-29T13:10:15.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 725,
                    service_id: 363,
                    name: "Classic manicure",
                    lang: "en",
                    created_at: "2021-12-29T13:10:15.000000Z",
                    updated_at: "2021-12-29T13:10:15.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 726,
                    service_id: 363,
                    name: "مناكير كلاسيك",
                    lang: "ar",
                    created_at: "2021-12-29T13:10:15.000000Z",
                    updated_at: "2021-12-29T13:10:15.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2642,
            salon_id: 1,
            specified_id: 1,
            service_id: 366,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:18:20.000000Z",
            updated_at: "2022-12-11T12:18:20.000000Z",
            deleted_at: null,
            service: {
                id: 366,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-29T13:26:43.000000Z",
                updated_at: "2021-12-29T13:26:43.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 731,
                    service_id: 366,
                    name: "file nails with color",
                    lang: "en",
                    created_at: "2021-12-29T13:26:43.000000Z",
                    updated_at: "2021-12-29T13:26:43.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 732,
                    service_id: 366,
                    name: "برد ولون",
                    lang: "ar",
                    created_at: "2021-12-29T13:26:43.000000Z",
                    updated_at: "2021-12-29T13:26:43.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2643,
            salon_id: 1,
            specified_id: 1,
            service_id: 367,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:18:20.000000Z",
            updated_at: "2022-12-11T12:18:20.000000Z",
            deleted_at: null,
            service: {
                id: 367,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-29T13:27:11.000000Z",
                updated_at: "2021-12-29T13:27:11.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 733,
                    service_id: 367,
                    name: "poly gel",
                    lang: "en",
                    created_at: "2021-12-29T13:27:11.000000Z",
                    updated_at: "2021-12-29T13:27:11.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 734,
                    service_id: 367,
                    name: "بولي جل",
                    lang: "ar",
                    created_at: "2021-12-29T13:27:11.000000Z",
                    updated_at: "2021-12-29T13:27:11.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2644,
            salon_id: 1,
            specified_id: 1,
            service_id: 388,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:18:20.000000Z",
            updated_at: "2022-12-11T12:18:20.000000Z",
            deleted_at: null,
            service: {
                id: 388,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-01-03T15:51:39.000000Z",
                updated_at: "2022-01-03T15:51:39.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 775,
                    service_id: 388,
                    name: "Add color for the hands",
                    lang: "en",
                    created_at: "2022-01-03T15:51:39.000000Z",
                    updated_at: "2022-01-03T15:51:39.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 776,
                    service_id: 388,
                    name: "إضافة لون لليدين",
                    lang: "ar",
                    created_at: "2022-01-03T15:51:39.000000Z",
                    updated_at: "2022-01-03T15:51:39.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2645,
            salon_id: 1,
            specified_id: 1,
            service_id: 491,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:19:18.000000Z",
            updated_at: "2022-12-11T12:19:18.000000Z",
            deleted_at: null,
            service: {
                id: 491,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:39:18.000000Z",
                updated_at: "2022-04-16T21:39:18.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 981,
                    service_id: 491,
                    name: "Light gel layer to protect nails",
                    lang: "en",
                    created_at: "2022-04-16T21:39:18.000000Z",
                    updated_at: "2022-04-16T21:39:18.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 982,
                    service_id: 491,
                    name: "طبقة جل خفيفة لحماية الأظافر",
                    lang: "ar",
                    created_at: "2022-04-16T21:39:18.000000Z",
                    updated_at: "2022-04-16T21:39:18.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2646,
            salon_id: 1,
            specified_id: 1,
            service_id: 492,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:19:18.000000Z",
            updated_at: "2022-12-11T12:19:18.000000Z",
            deleted_at: null,
            service: {
                id: 492,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:39:47.000000Z",
                updated_at: "2022-04-16T21:39:47.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 983,
                    service_id: 492,
                    name: "Cut and file fingernails",
                    lang: "en",
                    created_at: "2022-04-16T21:39:47.000000Z",
                    updated_at: "2022-04-16T21:39:47.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 984,
                    service_id: 492,
                    name: "قص وبرد أظافر اليدين",
                    lang: "ar",
                    created_at: "2022-04-16T21:39:47.000000Z",
                    updated_at: "2022-04-16T21:39:47.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2647,
            salon_id: 1,
            specified_id: 1,
            service_id: 493,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:19:18.000000Z",
            updated_at: "2022-12-11T12:19:18.000000Z",
            deleted_at: null,
            service: {
                id: 493,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:40:29.000000Z",
                updated_at: "2022-04-16T21:40:29.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 985,
                    service_id: 493,
                    name: "Cut and file toenails",
                    lang: "en",
                    created_at: "2022-04-16T21:40:29.000000Z",
                    updated_at: "2022-04-16T21:40:29.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 986,
                    service_id: 493,
                    name: "قص وبرد أظافر القدمين",
                    lang: "ar",
                    created_at: "2022-04-16T21:40:29.000000Z",
                    updated_at: "2022-04-16T21:40:29.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2648,
            salon_id: 1,
            specified_id: 1,
            service_id: 494,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:19:18.000000Z",
            updated_at: "2022-12-11T12:19:18.000000Z",
            deleted_at: null,
            service: {
                id: 494,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:41:00.000000Z",
                updated_at: "2022-04-16T21:41:00.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 987,
                    service_id: 494,
                    name: "Plain chrome powder",
                    lang: "en",
                    created_at: "2022-04-16T21:41:00.000000Z",
                    updated_at: "2022-04-16T21:41:00.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 988,
                    service_id: 494,
                    name: "كروم باودر عادي",
                    lang: "ar",
                    created_at: "2022-04-16T21:41:00.000000Z",
                    updated_at: "2022-04-16T21:41:00.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2649,
            salon_id: 1,
            specified_id: 1,
            service_id: 495,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:19:18.000000Z",
            updated_at: "2022-12-11T12:19:18.000000Z",
            deleted_at: null,
            service: {
                id: 495,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:49:58.000000Z",
                updated_at: "2022-04-16T21:49:58.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 989,
                    service_id: 495,
                    name: "Paraffin Angel for Hands Treatment",
                    lang: "en",
                    created_at: "2022-04-16T21:49:58.000000Z",
                    updated_at: "2022-04-16T21:49:58.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 990,
                    service_id: 495,
                    name: "علاج بارافين انجل هاندز لليدين",
                    lang: "ar",
                    created_at: "2022-04-16T21:49:58.000000Z",
                    updated_at: "2022-04-16T21:49:58.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2650,
            salon_id: 1,
            specified_id: 1,
            service_id: 496,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:19:18.000000Z",
            updated_at: "2022-12-11T12:19:18.000000Z",
            deleted_at: null,
            service: {
                id: 496,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:50:55.000000Z",
                updated_at: "2022-04-16T21:50:55.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 991,
                    service_id: 496,
                    name: "Paraffin Angel Hands for Foot Treatment",
                    lang: "en",
                    created_at: "2022-04-16T21:50:55.000000Z",
                    updated_at: "2022-04-16T21:50:55.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 992,
                    service_id: 496,
                    name: "علاج بارافين انجل هاندز للقدمين",
                    lang: "ar",
                    created_at: "2022-04-16T21:50:55.000000Z",
                    updated_at: "2022-04-16T21:50:55.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2651,
            salon_id: 1,
            specified_id: 1,
            service_id: 498,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:19:18.000000Z",
            updated_at: "2022-12-11T12:19:18.000000Z",
            deleted_at: null,
            service: {
                id: 498,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:52:00.000000Z",
                updated_at: "2022-04-16T21:52:00.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 995,
                    service_id: 498,
                    name: "Gold treatment for feet",
                    lang: "en",
                    created_at: "2022-04-16T21:52:00.000000Z",
                    updated_at: "2022-04-16T21:52:00.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 996,
                    service_id: 498,
                    name: "علاج الذهب للقدمين",
                    lang: "ar",
                    created_at: "2022-04-16T21:52:00.000000Z",
                    updated_at: "2022-04-16T21:52:00.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2652,
            salon_id: 1,
            specified_id: 1,
            service_id: 497,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:19:18.000000Z",
            updated_at: "2022-12-11T12:19:18.000000Z",
            deleted_at: null,
            service: {
                id: 497,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:51:30.000000Z",
                updated_at: "2022-04-16T21:51:30.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 993,
                    service_id: 497,
                    name: "Gold treatment for hands",
                    lang: "en",
                    created_at: "2022-04-16T21:51:30.000000Z",
                    updated_at: "2022-04-16T21:51:30.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 994,
                    service_id: 497,
                    name: "علاج الذهب لليدين",
                    lang: "ar",
                    created_at: "2022-04-16T21:51:30.000000Z",
                    updated_at: "2022-04-16T21:51:30.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2653,
            salon_id: 1,
            specified_id: 1,
            service_id: 499,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:19:18.000000Z",
            updated_at: "2022-12-11T12:19:18.000000Z",
            deleted_at: null,
            service: {
                id: 499,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:53:01.000000Z",
                updated_at: "2022-04-16T21:53:29.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 997,
                    service_id: 499,
                    name: "Angel Hands Set for Hand and Feet Care",
                    lang: "en",
                    created_at: "2022-04-16T21:53:01.000000Z",
                    updated_at: "2022-04-16T21:53:29.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 998,
                    service_id: 499,
                    name: "مجموعة انجل هاندز للعناية باليدين والقدمين",
                    lang: "ar",
                    created_at: "2022-04-16T21:53:01.000000Z",
                    updated_at: "2022-04-16T21:53:01.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2654,
            salon_id: 1,
            specified_id: 1,
            service_id: 500,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:19:18.000000Z",
            updated_at: "2022-12-11T12:19:18.000000Z",
            deleted_at: null,
            service: {
                id: 500,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-04-16T21:54:03.000000Z",
                updated_at: "2022-04-16T21:54:03.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 999,
                    service_id: 500,
                    name: "Detox set for hands and feet",
                    lang: "en",
                    created_at: "2022-04-16T21:54:03.000000Z",
                    updated_at: "2022-04-16T21:54:03.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1000,
                    service_id: 500,
                    name: "مجموعة الديتوكس للعناية باليدين والقدمين",
                    lang: "ar",
                    created_at: "2022-04-16T21:54:03.000000Z",
                    updated_at: "2022-04-16T21:54:03.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2655,
            salon_id: 1,
            specified_id: 1,
            service_id: 21,
            services_time_id: 3,
            amount: "5.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:20:39.000000Z",
            updated_at: "2023-01-17T09:56:46.000000Z",
            deleted_at: null,
            service: {
                id: 21,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 41,
                    service_id: 21,
                    name: "One color | short",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 42,
                    service_id: 21,
                    name: "صبغة لون واحد | قصير",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2656,
            salon_id: 1,
            specified_id: 1,
            service_id: 24,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:20:39.000000Z",
            updated_at: "2022-12-11T12:20:39.000000Z",
            deleted_at: null,
            service: {
                id: 24,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 47,
                    service_id: 24,
                    name: "Hair color with ammonia | short",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 48,
                    service_id: 24,
                    name: "صبغة لون واحد مع الأمونيا | قصير",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2657,
            salon_id: 1,
            specified_id: 1,
            service_id: 30,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:20:54.000000Z",
            updated_at: "2022-12-11T12:20:54.000000Z",
            deleted_at: null,
            service: {
                id: 30,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 59,
                    service_id: 30,
                    name: "Hair color with bleaching | short",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 60,
                    service_id: 30,
                    name: "صبغة لون واحد مع سحب لون | قصير",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2658,
            salon_id: 1,
            specified_id: 1,
            service_id: 29,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:20:54.000000Z",
            updated_at: "2022-12-11T12:20:54.000000Z",
            deleted_at: null,
            service: {
                id: 29,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 57,
                    service_id: 29,
                    name: "Hair color with ammonia-free | long",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 58,
                    service_id: 29,
                    name: "صبغة لون واحد خالية من الامونيا | طويل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2659,
            salon_id: 1,
            specified_id: 1,
            service_id: 33,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:21:16.000000Z",
            updated_at: "2022-12-11T12:21:16.000000Z",
            deleted_at: null,
            service: {
                id: 33,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 65,
                    service_id: 33,
                    name: "Hair color without bleaching | short",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 66,
                    service_id: 33,
                    name: "صبغة لون واحد بدون سحب لون | قصير",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2660,
            salon_id: 1,
            specified_id: 1,
            service_id: 34,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:21:16.000000Z",
            updated_at: "2022-12-11T12:21:16.000000Z",
            deleted_at: null,
            service: {
                id: 34,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 67,
                    service_id: 34,
                    name: "Hair color without bleaching | medium",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 68,
                    service_id: 34,
                    name: "صبغة لون واحد بدون سحب لون | متوسط",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2661,
            salon_id: 1,
            specified_id: 1,
            service_id: 37,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:21:35.000000Z",
            updated_at: "2022-12-11T12:21:35.000000Z",
            deleted_at: null,
            service: {
                id: 37,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 73,
                    service_id: 37,
                    name: "High & low light  | medium",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 74,
                    service_id: 37,
                    name: "صبغة خصل لايت لولايت | متوسط",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2662,
            salon_id: 1,
            specified_id: 1,
            service_id: 36,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:21:35.000000Z",
            updated_at: "2022-12-11T12:21:35.000000Z",
            deleted_at: null,
            service: {
                id: 36,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 71,
                    service_id: 36,
                    name: "High & low light  | short",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 72,
                    service_id: 36,
                    name: "صبغة خصل لايت لولايت | قصير",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2663,
            salon_id: 1,
            specified_id: 1,
            service_id: 39,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:21:35.000000Z",
            updated_at: "2022-12-11T12:21:35.000000Z",
            deleted_at: null,
            service: {
                id: 39,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 77,
                    service_id: 39,
                    name: "MAJIREL Hair color | short",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 78,
                    service_id: 39,
                    name: "الوان الماجاريل | قصير",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2664,
            salon_id: 1,
            specified_id: 1,
            service_id: 42,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:07.000000Z",
            updated_at: "2022-12-11T12:22:07.000000Z",
            deleted_at: null,
            service: {
                id: 42,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 83,
                    service_id: 42,
                    name: "Toner | short",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 84,
                    service_id: 42,
                    name: "تفتيح لون | قصير",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2665,
            salon_id: 1,
            specified_id: 1,
            service_id: 41,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:07.000000Z",
            updated_at: "2022-12-11T12:22:07.000000Z",
            deleted_at: null,
            service: {
                id: 41,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 81,
                    service_id: 41,
                    name: "MAJIREL Hair color | long",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 82,
                    service_id: 41,
                    name: "الوان الماجاريل | طويل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2666,
            salon_id: 1,
            specified_id: 1,
            service_id: 40,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:07.000000Z",
            updated_at: "2022-12-11T12:22:07.000000Z",
            deleted_at: null,
            service: {
                id: 40,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 79,
                    service_id: 40,
                    name: "MAJIREL Hair color | medium",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 80,
                    service_id: 40,
                    name: "الوان الماجاريل | متوسط",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2667,
            salon_id: 1,
            specified_id: 1,
            service_id: 43,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:07.000000Z",
            updated_at: "2022-12-11T12:22:07.000000Z",
            deleted_at: null,
            service: {
                id: 43,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 85,
                    service_id: 43,
                    name: "Toner | medium",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 86,
                    service_id: 43,
                    name: "تفتيح لون | متوسط",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2668,
            salon_id: 1,
            specified_id: 1,
            service_id: 44,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:07.000000Z",
            updated_at: "2022-12-11T12:22:07.000000Z",
            deleted_at: null,
            service: {
                id: 44,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 87,
                    service_id: 44,
                    name: "Toner | long",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 88,
                    service_id: 44,
                    name: "تفتيح لون | طويل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2669,
            salon_id: 1,
            specified_id: 1,
            service_id: 55,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:57.000000Z",
            updated_at: "2022-12-11T12:22:57.000000Z",
            deleted_at: null,
            service: {
                id: 55,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 109,
                    service_id: 55,
                    name: "Ombre with color | short",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 110,
                    service_id: 55,
                    name: "أومبري | قصير",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2670,
            salon_id: 1,
            specified_id: 1,
            service_id: 56,
            services_time_id: 4,
            amount: "11.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:57.000000Z",
            updated_at: "2022-12-11T12:22:57.000000Z",
            deleted_at: null,
            service: {
                id: 56,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 111,
                    service_id: 56,
                    name: "Ombre with color | medium",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 112,
                    service_id: 56,
                    name: "أومبري | متوسط",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2671,
            salon_id: 1,
            specified_id: 1,
            service_id: 57,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:57.000000Z",
            updated_at: "2022-12-11T12:22:57.000000Z",
            deleted_at: null,
            service: {
                id: 57,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 113,
                    service_id: 57,
                    name: "Ombre with color | long",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 114,
                    service_id: 57,
                    name: "أومبري | طويل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2672,
            salon_id: 1,
            specified_id: 1,
            service_id: 58,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:57.000000Z",
            updated_at: "2022-12-11T12:22:57.000000Z",
            deleted_at: null,
            service: {
                id: 58,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 115,
                    service_id: 58,
                    name: "Highlight | short",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 116,
                    service_id: 58,
                    name: "هاي لايت | قصير",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2673,
            salon_id: 1,
            specified_id: 1,
            service_id: 59,
            services_time_id: 1,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:57.000000Z",
            updated_at: "2022-12-11T12:22:57.000000Z",
            deleted_at: null,
            service: {
                id: 59,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 1,
                time: 1,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:09:37.000000Z",
                updated_at: "2021-12-23T17:09:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 117,
                    service_id: 59,
                    name: "Highlight|  medium",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 118,
                    service_id: 59,
                    name: "هاي لايت | متوسط",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2674,
            salon_id: 1,
            specified_id: 1,
            service_id: 60,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:57.000000Z",
            updated_at: "2022-12-11T12:22:57.000000Z",
            deleted_at: null,
            service: {
                id: 60,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 119,
                    service_id: 60,
                    name: "Highlight | long",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 120,
                    service_id: 60,
                    name: "هاي لايت | طويل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2675,
            salon_id: 1,
            specified_id: 1,
            service_id: 61,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:57.000000Z",
            updated_at: "2022-12-11T12:22:57.000000Z",
            deleted_at: null,
            service: {
                id: 61,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 121,
                    service_id: 61,
                    name: "INOA color |  short",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 122,
                    service_id: 61,
                    name: "الوان الانوا | قصير",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2676,
            salon_id: 1,
            specified_id: 1,
            service_id: 62,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:57.000000Z",
            updated_at: "2022-12-11T12:22:57.000000Z",
            deleted_at: null,
            service: {
                id: 62,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 123,
                    service_id: 62,
                    name: "INOA color | medium",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 124,
                    service_id: 62,
                    name: "الوان الانوا | متوسط",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2677,
            salon_id: 1,
            specified_id: 1,
            service_id: 63,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:22:57.000000Z",
            updated_at: "2022-12-11T12:22:57.000000Z",
            deleted_at: null,
            service: {
                id: 63,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 125,
                    service_id: 63,
                    name: "INOA color | long",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 126,
                    service_id: 63,
                    name: "الوان الانوا | طويل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2678,
            salon_id: 1,
            specified_id: 1,
            service_id: 389,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:24:02.000000Z",
            updated_at: "2022-12-11T12:24:02.000000Z",
            deleted_at: null,
            service: {
                id: 389,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-01-03T15:56:03.000000Z",
                updated_at: "2022-01-03T15:56:03.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 777,
                    service_id: 389,
                    name: "Rensaj",
                    lang: "en",
                    created_at: "2022-01-03T15:56:03.000000Z",
                    updated_at: "2022-01-03T15:56:03.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 778,
                    service_id: 389,
                    name: "رنساج",
                    lang: "ar",
                    created_at: "2022-01-03T15:56:03.000000Z",
                    updated_at: "2022-01-03T15:56:03.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2679,
            salon_id: 1,
            specified_id: 1,
            service_id: 390,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:24:02.000000Z",
            updated_at: "2022-12-11T12:24:02.000000Z",
            deleted_at: null,
            service: {
                id: 390,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-01-03T15:56:49.000000Z",
                updated_at: "2022-01-03T15:56:49.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 779,
                    service_id: 390,
                    name: "Roots tincture",
                    lang: "en",
                    created_at: "2022-01-03T15:56:49.000000Z",
                    updated_at: "2022-01-03T15:56:49.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 780,
                    service_id: 390,
                    name: "صبغة الجذور",
                    lang: "ar",
                    created_at: "2022-01-03T15:56:49.000000Z",
                    updated_at: "2022-01-03T15:56:49.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2680,
            salon_id: 1,
            specified_id: 1,
            service_id: 406,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:24:43.000000Z",
            updated_at: "2022-12-11T12:24:43.000000Z",
            deleted_at: null,
            service: {
                id: 406,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-02-20T14:03:44.000000Z",
                updated_at: "2022-02-20T14:03:44.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 811,
                    service_id: 406,
                    name: "Organic Roots | Short",
                    lang: "en",
                    created_at: "2022-02-20T14:03:44.000000Z",
                    updated_at: "2022-02-20T14:03:44.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 812,
                    service_id: 406,
                    name: "صبغة عضوي | قصير",
                    lang: "ar",
                    created_at: "2022-02-20T14:03:44.000000Z",
                    updated_at: "2022-02-20T14:03:44.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2681,
            salon_id: 1,
            specified_id: 1,
            service_id: 915,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:24:43.000000Z",
            updated_at: "2022-12-11T12:24:43.000000Z",
            deleted_at: null,
            service: {
                id: 915,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-28T06:35:14.000000Z",
                updated_at: "2022-11-28T06:35:14.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1829,
                    service_id: 915,
                    name: "Hair dye | short",
                    lang: "en",
                    created_at: "2022-11-28T06:35:14.000000Z",
                    updated_at: "2022-11-28T06:35:14.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1830,
                    service_id: 915,
                    name: "صبغة شعر | قصير",
                    lang: "ar",
                    created_at: "2022-11-28T06:35:14.000000Z",
                    updated_at: "2022-11-28T06:35:14.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2682,
            salon_id: 1,
            specified_id: 1,
            service_id: 412,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:25:03.000000Z",
            updated_at: "2022-12-11T12:25:03.000000Z",
            deleted_at: null,
            service: {
                id: 412,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-02-20T14:12:25.000000Z",
                updated_at: "2022-02-20T14:12:25.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 823,
                    service_id: 412,
                    name: "Organic Ombre | Short",
                    lang: "en",
                    created_at: "2022-02-20T14:12:25.000000Z",
                    updated_at: "2022-02-20T14:12:25.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 824,
                    service_id: 412,
                    name: "أومبري عضوي  | قصير",
                    lang: "ar",
                    created_at: "2022-02-20T14:12:25.000000Z",
                    updated_at: "2022-02-20T14:12:25.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2683,
            salon_id: 1,
            specified_id: 1,
            service_id: 413,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:25:03.000000Z",
            updated_at: "2022-12-11T12:25:03.000000Z",
            deleted_at: null,
            service: {
                id: 413,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-02-20T14:13:01.000000Z",
                updated_at: "2022-02-20T14:13:01.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 825,
                    service_id: 413,
                    name: "Organic Ombre | Medium",
                    lang: "en",
                    created_at: "2022-02-20T14:13:01.000000Z",
                    updated_at: "2022-02-20T14:13:01.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 826,
                    service_id: 413,
                    name: "أومبري عضوي | متوسط",
                    lang: "ar",
                    created_at: "2022-02-20T14:13:01.000000Z",
                    updated_at: "2022-02-20T14:13:01.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2684,
            salon_id: 1,
            specified_id: 1,
            service_id: 351,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:25:29.000000Z",
            updated_at: "2022-12-11T12:25:29.000000Z",
            deleted_at: null,
            service: {
                id: 351,
                category_id: 3,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-28T12:03:38.000000Z",
                updated_at: "2021-12-28T12:03:38.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 701,
                    service_id: 351,
                    name: "Classic facial cleansing",
                    lang: "en",
                    created_at: "2021-12-28T12:03:38.000000Z",
                    updated_at: "2021-12-28T12:03:38.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 702,
                    service_id: 351,
                    name: "تنظيف بشرة كلاسيك",
                    lang: "ar",
                    created_at: "2021-12-28T12:03:38.000000Z",
                    updated_at: "2021-12-28T12:03:38.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2685,
            salon_id: 1,
            specified_id: 1,
            service_id: 352,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:25:29.000000Z",
            updated_at: "2022-12-11T12:25:29.000000Z",
            deleted_at: null,
            service: {
                id: 352,
                category_id: 3,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-28T12:04:06.000000Z",
                updated_at: "2021-12-28T12:04:06.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 703,
                    service_id: 352,
                    name: "Deep clean facial cleansing",
                    lang: "en",
                    created_at: "2021-12-28T12:04:06.000000Z",
                    updated_at: "2021-12-28T12:04:06.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 704,
                    service_id: 352,
                    name: "تنظيف البشرة العميق",
                    lang: "ar",
                    created_at: "2021-12-28T12:04:06.000000Z",
                    updated_at: "2021-12-28T12:04:06.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2686,
            salon_id: 1,
            specified_id: 1,
            service_id: 709,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:25:51.000000Z",
            updated_at: "2022-12-11T12:25:51.000000Z",
            deleted_at: null,
            service: {
                id: 709,
                category_id: 3,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-16T08:13:52.000000Z",
                updated_at: "2022-11-16T08:13:52.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1417,
                    service_id: 709,
                    name: "Mini facial",
                    lang: "en",
                    created_at: "2022-11-16T08:13:52.000000Z",
                    updated_at: "2022-11-16T08:13:52.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1418,
                    service_id: 709,
                    name: "ميني فيشل",
                    lang: "ar",
                    created_at: "2022-11-16T08:13:52.000000Z",
                    updated_at: "2022-11-16T08:13:52.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2687,
            salon_id: 1,
            specified_id: 1,
            service_id: 688,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:25:51.000000Z",
            updated_at: "2022-12-11T12:25:51.000000Z",
            deleted_at: null,
            service: {
                id: 688,
                category_id: 3,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-16T07:33:38.000000Z",
                updated_at: "2022-11-16T07:33:38.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1375,
                    service_id: 688,
                    name: "Gold mask",
                    lang: "en",
                    created_at: "2022-11-16T07:33:38.000000Z",
                    updated_at: "2022-11-16T07:33:38.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1376,
                    service_id: 688,
                    name: "ماسك الذهب",
                    lang: "ar",
                    created_at: "2022-11-16T07:33:38.000000Z",
                    updated_at: "2022-11-16T07:33:38.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2688,
            salon_id: 1,
            specified_id: 1,
            service_id: 687,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:26:05.000000Z",
            updated_at: "2022-12-11T12:26:05.000000Z",
            deleted_at: null,
            service: {
                id: 687,
                category_id: 3,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-16T07:33:08.000000Z",
                updated_at: "2022-11-16T07:33:08.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1373,
                    service_id: 687,
                    name: "VIP Skin Cleaning with HydraFacial",
                    lang: "en",
                    created_at: "2022-11-16T07:33:08.000000Z",
                    updated_at: "2022-11-16T07:33:08.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1374,
                    service_id: 687,
                    name: "تنظيف بشرة VIP مع هايدرا فيشل",
                    lang: "ar",
                    created_at: "2022-11-16T07:33:08.000000Z",
                    updated_at: "2022-11-16T07:33:08.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2689,
            salon_id: 1,
            specified_id: 1,
            service_id: 768,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:26:05.000000Z",
            updated_at: "2022-12-11T12:26:05.000000Z",
            deleted_at: null,
            service: {
                id: 768,
                category_id: 3,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-16T11:39:24.000000Z",
                updated_at: "2022-11-16T11:39:24.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1535,
                    service_id: 768,
                    name: "Body whitening cream with roses and Aker Fassi",
                    lang: "en",
                    created_at: "2022-11-16T11:39:24.000000Z",
                    updated_at: "2022-11-16T11:39:24.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1536,
                    service_id: 768,
                    name: "كريم تفتيح الجسم بالورد والعكر الفاسي",
                    lang: "ar",
                    created_at: "2022-11-16T11:39:24.000000Z",
                    updated_at: "2022-11-16T11:39:24.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2690,
            salon_id: 1,
            specified_id: 1,
            service_id: 750,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:26:25.000000Z",
            updated_at: "2022-12-11T12:26:25.000000Z",
            deleted_at: null,
            service: {
                id: 750,
                category_id: 4,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-16T10:24:58.000000Z",
                updated_at: "2022-11-16T10:24:58.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1499,
                    service_id: 750,
                    name: "Special Makeup",
                    lang: "en",
                    created_at: "2022-11-16T10:24:58.000000Z",
                    updated_at: "2022-11-16T10:24:58.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1500,
                    service_id: 750,
                    name: "مكياج سبيشال",
                    lang: "ar",
                    created_at: "2022-11-16T10:24:58.000000Z",
                    updated_at: "2022-11-16T10:24:58.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2691,
            salon_id: 1,
            specified_id: 1,
            service_id: 749,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:26:25.000000Z",
            updated_at: "2022-12-11T12:26:25.000000Z",
            deleted_at: null,
            service: {
                id: 749,
                category_id: 4,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-16T10:22:33.000000Z",
                updated_at: "2022-11-16T10:22:33.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1497,
                    service_id: 749,
                    name: "Upper body makeup only",
                    lang: "en",
                    created_at: "2022-11-16T10:22:33.000000Z",
                    updated_at: "2022-11-16T10:22:33.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1498,
                    service_id: 749,
                    name: "مكياج جسم علوي فقط",
                    lang: "ar",
                    created_at: "2022-11-16T10:22:33.000000Z",
                    updated_at: "2022-11-16T10:22:33.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2692,
            salon_id: 1,
            specified_id: 1,
            service_id: 72,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:30:31.000000Z",
            updated_at: "2022-12-11T12:30:31.000000Z",
            deleted_at: null,
            service: {
                id: 72,
                category_id: 4,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 143,
                    service_id: 72,
                    name: "Full makeup ",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 144,
                    service_id: 72,
                    name: "مكياج كامل",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2693,
            salon_id: 1,
            specified_id: 1,
            service_id: 71,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:30:31.000000Z",
            updated_at: "2022-12-11T12:30:31.000000Z",
            deleted_at: null,
            service: {
                id: 71,
                category_id: 4,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 141,
                    service_id: 71,
                    name: "Super Glam makeup",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 142,
                    service_id: 71,
                    name: "مكياج سهرة",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2694,
            salon_id: 1,
            specified_id: 1,
            service_id: 73,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:30:31.000000Z",
            updated_at: "2022-12-11T12:30:31.000000Z",
            deleted_at: null,
            service: {
                id: 73,
                category_id: 4,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 145,
                    service_id: 73,
                    name: "Bridel makeup",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 146,
                    service_id: 73,
                    name: "مكياج العروس",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2695,
            salon_id: 1,
            specified_id: 1,
            service_id: 74,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:30:31.000000Z",
            updated_at: "2022-12-11T12:30:31.000000Z",
            deleted_at: null,
            service: {
                id: 74,
                category_id: 4,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 147,
                    service_id: 74,
                    name: "Eye makeup",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 148,
                    service_id: 74,
                    name: "مكياج عيون",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2696,
            salon_id: 1,
            specified_id: 1,
            service_id: 659,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:30:31.000000Z",
            updated_at: "2022-12-11T12:30:31.000000Z",
            deleted_at: null,
            service: {
                id: 659,
                category_id: 4,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-15T08:27:58.000000Z",
                updated_at: "2022-11-15T08:27:58.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1317,
                    service_id: 659,
                    name: "Lips blushing with lightning",
                    lang: "en",
                    created_at: "2022-11-15T08:27:58.000000Z",
                    updated_at: "2022-11-15T08:27:58.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1318,
                    service_id: 659,
                    name: "توريد شفايف مع تفتيح",
                    lang: "ar",
                    created_at: "2022-11-15T08:27:58.000000Z",
                    updated_at: "2022-11-15T08:27:58.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2697,
            salon_id: 1,
            specified_id: 1,
            service_id: 385,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:30:32.000000Z",
            updated_at: "2022-12-11T12:30:32.000000Z",
            deleted_at: null,
            service: {
                id: 385,
                category_id: 4,
                icon: null,
                status: 1,
                approved: 0,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-01-03T11:19:34.000000Z",
                updated_at: "2022-01-03T11:19:34.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 769,
                    service_id: 385,
                    name: "Eyeliner",
                    lang: "en",
                    created_at: "2022-01-03T11:19:34.000000Z",
                    updated_at: "2022-01-03T11:19:34.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 770,
                    service_id: 385,
                    name: "ايلاينر",
                    lang: "ar",
                    created_at: "2022-01-03T11:19:34.000000Z",
                    updated_at: "2022-01-03T11:19:34.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2698,
            salon_id: 1,
            specified_id: 1,
            service_id: 362,
            services_time_id: 4,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:30:32.000000Z",
            updated_at: "2022-12-11T12:30:32.000000Z",
            deleted_at: null,
            service: {
                id: 362,
                category_id: 4,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-29T12:49:37.000000Z",
                updated_at: "2021-12-29T12:49:37.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 4,
                time: 15,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:37.000000Z",
                updated_at: "2021-12-23T17:28:37.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 723,
                    service_id: 362,
                    name: "soft makeup",
                    lang: "en",
                    created_at: "2021-12-29T12:49:37.000000Z",
                    updated_at: "2021-12-29T12:49:37.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 724,
                    service_id: 362,
                    name: "مكياج ناعم",
                    lang: "ar",
                    created_at: "2021-12-29T12:49:37.000000Z",
                    updated_at: "2021-12-29T12:49:37.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2699,
            salon_id: 1,
            specified_id: 1,
            service_id: 11,
            services_time_id: 2,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:32:18.000000Z",
            updated_at: "2022-12-11T12:32:18.000000Z",
            deleted_at: null,
            service: {
                id: 11,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 2,
                time: 5,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:27:59.000000Z",
                updated_at: "2021-12-23T17:27:59.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 21,
                    service_id: 11,
                    name: "french manicure",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 22,
                    service_id: 11,
                    name: "مناكير فرنسي",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2700,
            salon_id: 1,
            specified_id: 1,
            service_id: 12,
            services_time_id: 3,
            amount: "1.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2022-12-11T12:32:18.000000Z",
            updated_at: "2022-12-11T12:32:18.000000Z",
            deleted_at: null,
            service: {
                id: 12,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 23,
                    service_id: 12,
                    name: "french manicure for hands",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 24,
                    service_id: 12,
                    name: "مناكير فرنسي لليدين",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2935,
            salon_id: 1,
            specified_id: 1,
            service_id: 636,
            services_time_id: 9,
            amount: "44.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2023-01-15T10:52:40.000000Z",
            updated_at: "2023-01-15T10:52:40.000000Z",
            deleted_at: null,
            service: {
                id: 636,
                category_id: 1,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-15T07:28:00.000000Z",
                updated_at: "2022-11-15T07:28:00.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 9,
                time: 40,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:40:01.000000Z",
                updated_at: "2021-12-23T17:40:01.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 1271,
                    service_id: 636,
                    name: "Paraffin for hands",
                    lang: "en",
                    created_at: "2022-11-15T07:28:00.000000Z",
                    updated_at: "2022-11-15T07:28:00.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 1272,
                    service_id: 636,
                    name: "برافين لليدين",
                    lang: "ar",
                    created_at: "2022-11-15T07:28:00.000000Z",
                    updated_at: "2022-11-15T07:28:00.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2937,
            salon_id: 1,
            specified_id: 1,
            service_id: 27,
            services_time_id: 9,
            amount: "55.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2023-01-17T09:56:27.000000Z",
            updated_at: "2023-01-17T09:56:27.000000Z",
            deleted_at: null,
            service: {
                id: 27,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 9,
                time: 40,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:40:01.000000Z",
                updated_at: "2021-12-23T17:40:01.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 53,
                    service_id: 27,
                    name: "Hair color with ammonia-free | short",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 54,
                    service_id: 27,
                    name: "صبغة لون واحد خالية من الأمونيا | قصير",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        },
        {
            id: 2938,
            salon_id: 1,
            specified_id: 1,
            service_id: 31,
            services_time_id: 3,
            amount: "100.00",
            created_by: 2,
            updated_by: 2,
            created_at: "2023-01-17T09:56:27.000000Z",
            updated_at: "2023-01-17T09:56:27.000000Z",
            deleted_at: null,
            service: {
                id: 31,
                category_id: 2,
                icon: null,
                status: 1,
                approved: 1,
                salon_id: 0,
                created_by: 1,
                updated_by: 1,
                created_at: "2021-12-14T11:45:46.000000Z",
                updated_at: "2021-12-14T11:45:46.000000Z",
                deleted_at: null
            },
            services_time: {
                id: 3,
                time: 10,
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:28:17.000000Z",
                updated_at: "2021-12-23T17:28:17.000000Z",
                deleted_at: null
            },
            service_lang: {
                en: {
                    id: 61,
                    service_id: 31,
                    name: "Hair color with bleaching | medium",
                    lang: "en",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                ar: {
                    id: 62,
                    service_id: 31,
                    name: "صبغة لون واحد مع سحب لون | متوسط",
                    lang: "ar",
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                }
            }
        }
    ],
    catServices: {
        1: [
            {
                id: 1239,
                salon_id: 1,
                specified_id: 1,
                service_id: 3,
                services_time_id: 7,
                amount: "300.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-07-07T16:11:19.000000Z",
                updated_at: "2022-12-06T08:28:48.000000Z",
                deleted_at: null,
                service: {
                    id: 3,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 7,
                    time: 30,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:39:08.000000Z",
                    updated_at: "2021-12-23T17:39:08.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 5,
                        service_id: 3,
                        name: "Nail polish for hands",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 6,
                        service_id: 3,
                        name: "إضافة لون لليدين",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 1384,
                salon_id: 1,
                specified_id: 1,
                service_id: 5,
                services_time_id: 2,
                amount: "500.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-10-11T12:41:01.000000Z",
                updated_at: "2022-12-06T08:28:48.000000Z",
                deleted_at: null,
                service: {
                    id: 5,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 9,
                        service_id: 5,
                        name: "Paraffin hands",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 10,
                        service_id: 5,
                        name: "برافين لليدين",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2209,
                salon_id: 1,
                specified_id: 1,
                service_id: 1,
                services_time_id: 1,
                amount: "100.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-28T12:03:47.000000Z",
                updated_at: "2022-12-06T08:31:24.000000Z",
                deleted_at: null,
                service: {
                    id: 1,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1,
                        service_id: 1,
                        name: "Classic manicure",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 2,
                        service_id: 1,
                        name: "مناكير كلاسيك",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2210,
                salon_id: 1,
                specified_id: 1,
                service_id: 2,
                services_time_id: 3,
                amount: "200.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-28T12:03:47.000000Z",
                updated_at: "2022-12-06T08:31:24.000000Z",
                deleted_at: null,
                service: {
                    id: 2,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 3,
                        service_id: 2,
                        name: "Classic pedicure",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 4,
                        service_id: 2,
                        name: "بديكير كلاسيك",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2457,
                salon_id: 1,
                specified_id: 1,
                service_id: 4,
                services_time_id: 8,
                amount: "400.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-01T10:20:32.000000Z",
                updated_at: "2022-12-06T08:28:48.000000Z",
                deleted_at: null,
                service: {
                    id: 4,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 8,
                    time: 35,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:39:32.000000Z",
                    updated_at: "2021-12-23T17:39:32.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 7,
                        service_id: 4,
                        name: "Nail polish for feets",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 8,
                        service_id: 4,
                        name: "إضافة لون للرجلين",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2458,
                salon_id: 1,
                specified_id: 1,
                service_id: 7,
                services_time_id: 3,
                amount: "600.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-01T10:20:32.000000Z",
                updated_at: "2022-12-06T08:28:48.000000Z",
                deleted_at: null,
                service: {
                    id: 7,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 13,
                        service_id: 7,
                        name: "Gel extension",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 14,
                        service_id: 7,
                        name: "لون الجِل",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2613,
                salon_id: 1,
                specified_id: 1,
                service_id: 6,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T10:57:40.000000Z",
                updated_at: "2022-12-11T10:57:40.000000Z",
                deleted_at: null,
                service: {
                    id: 6,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 11,
                        service_id: 6,
                        name: "Paraffin feet",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 12,
                        service_id: 6,
                        name: "برافين للقدمين",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2614,
                salon_id: 1,
                specified_id: 1,
                service_id: 8,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T10:57:40.000000Z",
                updated_at: "2022-12-11T10:57:40.000000Z",
                deleted_at: null,
                service: {
                    id: 8,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 15,
                        service_id: 8,
                        name: "Gel polish removal",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 16,
                        service_id: 8,
                        name: "إزالة لون الجِل",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2615,
                salon_id: 1,
                specified_id: 1,
                service_id: 9,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:13:48.000000Z",
                updated_at: "2022-12-11T12:13:48.000000Z",
                deleted_at: null,
                service: {
                    id: 9,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 17,
                        service_id: 9,
                        name: "Hands shillac",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 18,
                        service_id: 9,
                        name: "لون شيلاك",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2616,
                salon_id: 1,
                specified_id: 1,
                service_id: 10,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:13:48.000000Z",
                updated_at: "2022-12-11T12:13:48.000000Z",
                deleted_at: null,
                service: {
                    id: 10,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 19,
                        service_id: 10,
                        name: "Ombre color",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 20,
                        service_id: 10,
                        name: "لون اومبريه",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2617,
                salon_id: 1,
                specified_id: 1,
                service_id: 13,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:14:02.000000Z",
                updated_at: "2022-12-11T12:14:02.000000Z",
                deleted_at: null,
                service: {
                    id: 13,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 25,
                        service_id: 13,
                        name: "french manicure for feets",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 26,
                        service_id: 13,
                        name: "مناكير فرنسي للرجلين",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2618,
                salon_id: 1,
                specified_id: 1,
                service_id: 14,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:14:02.000000Z",
                updated_at: "2022-12-11T12:14:02.000000Z",
                deleted_at: null,
                service: {
                    id: 14,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 27,
                        service_id: 14,
                        name: "french manicure for hands and feets",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 28,
                        service_id: 14,
                        name: "مناكير فرنسي لليدين والرجلين",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2619,
                salon_id: 1,
                specified_id: 1,
                service_id: 18,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:14:22.000000Z",
                updated_at: "2022-12-11T12:14:22.000000Z",
                deleted_at: null,
                service: {
                    id: 18,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 35,
                        service_id: 18,
                        name: "Nails extension | feets",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 36,
                        service_id: 18,
                        name: "تركيب أظافر مؤقتة للرجلين",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2620,
                salon_id: 1,
                specified_id: 1,
                service_id: 19,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:14:22.000000Z",
                updated_at: "2022-12-11T12:14:22.000000Z",
                deleted_at: null,
                service: {
                    id: 19,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 37,
                        service_id: 19,
                        name: "Nail art",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 38,
                        service_id: 19,
                        name: "رسم لأظفر واحد",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2621,
                salon_id: 1,
                specified_id: 1,
                service_id: 15,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:15:06.000000Z",
                updated_at: "2022-12-11T12:15:06.000000Z",
                deleted_at: null,
                service: {
                    id: 15,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 29,
                        service_id: 15,
                        name: "Acrylic Extension Full Set | Hands",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 30,
                        service_id: 15,
                        name: "تركيب أظافر أكريلك لليدين",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2622,
                salon_id: 1,
                specified_id: 1,
                service_id: 16,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:15:06.000000Z",
                updated_at: "2022-12-11T12:15:06.000000Z",
                deleted_at: null,
                service: {
                    id: 16,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 31,
                        service_id: 16,
                        name: "Acrylic Extension Full Set | Feets",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 32,
                        service_id: 16,
                        name: "تركيب أظافر أكريلك للرجلين",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2623,
                salon_id: 1,
                specified_id: 1,
                service_id: 364,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:15:19.000000Z",
                updated_at: "2022-12-11T12:15:19.000000Z",
                deleted_at: null,
                service: {
                    id: 364,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-29T13:10:46.000000Z",
                    updated_at: "2021-12-29T13:10:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 727,
                        service_id: 364,
                        name: "Classic pedicure",
                        lang: "en",
                        created_at: "2021-12-29T13:10:46.000000Z",
                        updated_at: "2021-12-29T13:10:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 728,
                        service_id: 364,
                        name: "بديكير كلاسيك",
                        lang: "ar",
                        created_at: "2021-12-29T13:10:46.000000Z",
                        updated_at: "2021-12-29T13:10:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2624,
                salon_id: 1,
                specified_id: 1,
                service_id: 365,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:15:19.000000Z",
                updated_at: "2022-12-11T12:15:19.000000Z",
                deleted_at: null,
                service: {
                    id: 365,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-29T13:14:15.000000Z",
                    updated_at: "2021-12-29T13:14:15.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 729,
                        service_id: 365,
                        name: "Classic Manicure & pedicure",
                        lang: "en",
                        created_at: "2021-12-29T13:14:15.000000Z",
                        updated_at: "2021-12-29T13:14:15.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 730,
                        service_id: 365,
                        name: "بديكير و مناكير كلاسيك",
                        lang: "ar",
                        created_at: "2021-12-29T13:14:15.000000Z",
                        updated_at: "2021-12-29T13:14:15.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2625,
                salon_id: 1,
                specified_id: 1,
                service_id: 368,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:15:31.000000Z",
                updated_at: "2022-12-11T12:15:31.000000Z",
                deleted_at: null,
                service: {
                    id: 368,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-29T13:27:36.000000Z",
                    updated_at: "2021-12-29T13:27:36.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 735,
                        service_id: 368,
                        name: "poly gel Ombre",
                        lang: "en",
                        created_at: "2021-12-29T13:27:36.000000Z",
                        updated_at: "2021-12-29T13:27:36.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 736,
                        service_id: 368,
                        name: "بولي جل مع أومبرية",
                        lang: "ar",
                        created_at: "2021-12-29T13:27:36.000000Z",
                        updated_at: "2021-12-29T13:27:36.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2626,
                salon_id: 1,
                specified_id: 1,
                service_id: 369,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:15:31.000000Z",
                updated_at: "2022-12-11T12:15:31.000000Z",
                deleted_at: null,
                service: {
                    id: 369,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-29T13:30:04.000000Z",
                    updated_at: "2021-12-29T13:30:04.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 737,
                        service_id: 369,
                        name: "Nails Color",
                        lang: "en",
                        created_at: "2021-12-29T13:30:04.000000Z",
                        updated_at: "2021-12-29T13:30:04.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 738,
                        service_id: 369,
                        name: "لون أظافر",
                        lang: "ar",
                        created_at: "2021-12-29T13:30:04.000000Z",
                        updated_at: "2021-12-29T13:30:04.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2627,
                salon_id: 1,
                specified_id: 1,
                service_id: 489,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:15:55.000000Z",
                updated_at: "2022-12-11T12:15:55.000000Z",
                deleted_at: null,
                service: {
                    id: 489,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:38:07.000000Z",
                    updated_at: "2022-04-16T21:38:07.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 977,
                        service_id: 489,
                        name: "Acrylic or gel nails",
                        lang: "en",
                        created_at: "2022-04-16T21:38:07.000000Z",
                        updated_at: "2022-04-16T21:38:07.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 978,
                        service_id: 489,
                        name: "أظافر اكريليك أو جل",
                        lang: "ar",
                        created_at: "2022-04-16T21:38:07.000000Z",
                        updated_at: "2022-04-16T21:38:07.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2628,
                salon_id: 1,
                specified_id: 1,
                service_id: 490,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:15:55.000000Z",
                updated_at: "2022-12-11T12:15:55.000000Z",
                deleted_at: null,
                service: {
                    id: 490,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:38:43.000000Z",
                    updated_at: "2022-04-16T21:38:43.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 979,
                        service_id: 490,
                        name: "French gel nail color",
                        lang: "en",
                        created_at: "2022-04-16T21:38:43.000000Z",
                        updated_at: "2022-04-16T21:38:43.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 980,
                        service_id: 490,
                        name: "لون أظافر جيل فرنش",
                        lang: "ar",
                        created_at: "2022-04-16T21:38:43.000000Z",
                        updated_at: "2022-04-16T21:38:43.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2629,
                salon_id: 1,
                specified_id: 1,
                service_id: 940,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:16:14.000000Z",
                updated_at: "2022-12-11T12:16:14.000000Z",
                deleted_at: null,
                service: {
                    id: 940,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-12-08T09:21:19.000000Z",
                    updated_at: "2022-12-08T09:21:19.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1879,
                        service_id: 940,
                        name: "Ombré for hands",
                        lang: "en",
                        created_at: "2022-12-08T09:21:19.000000Z",
                        updated_at: "2022-12-08T09:21:19.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1880,
                        service_id: 940,
                        name: "اومبريه لليدين",
                        lang: "ar",
                        created_at: "2022-12-08T09:21:19.000000Z",
                        updated_at: "2022-12-08T09:21:19.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2630,
                salon_id: 1,
                specified_id: 1,
                service_id: 810,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:16:14.000000Z",
                updated_at: "2022-12-11T12:16:14.000000Z",
                deleted_at: null,
                service: {
                    id: 810,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-23T07:41:24.000000Z",
                    updated_at: "2022-11-23T07:41:32.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1619,
                        service_id: 810,
                        name: "Spa manicure and pedicure VS",
                        lang: "en",
                        created_at: "2022-11-23T07:41:24.000000Z",
                        updated_at: "2022-11-23T07:41:24.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1620,
                        service_id: 810,
                        name: "بديكير ومناكير سبا VS",
                        lang: "ar",
                        created_at: "2022-11-23T07:41:24.000000Z",
                        updated_at: "2022-11-23T07:41:24.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2631,
                salon_id: 1,
                specified_id: 1,
                service_id: 798,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:16:31.000000Z",
                updated_at: "2022-12-11T12:16:31.000000Z",
                deleted_at: null,
                service: {
                    id: 798,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-20T08:28:12.000000Z",
                    updated_at: "2022-11-20T09:47:29.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1595,
                        service_id: 798,
                        name: "Acrylic gel ceramic",
                        lang: "en",
                        created_at: "2022-11-20T08:28:12.000000Z",
                        updated_at: "2022-11-20T08:28:12.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1596,
                        service_id: 798,
                        name: "اكريلك جل سيراميك",
                        lang: "ar",
                        created_at: "2022-11-20T08:28:12.000000Z",
                        updated_at: "2022-11-20T08:28:12.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2632,
                salon_id: 1,
                specified_id: 1,
                service_id: 797,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:16:31.000000Z",
                updated_at: "2022-12-11T12:16:31.000000Z",
                deleted_at: null,
                service: {
                    id: 797,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-20T08:27:26.000000Z",
                    updated_at: "2022-11-20T09:47:47.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1593,
                        service_id: 797,
                        name: "Ceramic gel nails",
                        lang: "en",
                        created_at: "2022-11-20T08:27:26.000000Z",
                        updated_at: "2022-11-20T08:27:26.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1594,
                        service_id: 797,
                        name: "أظافر جل سيراميك",
                        lang: "ar",
                        created_at: "2022-11-20T08:27:26.000000Z",
                        updated_at: "2022-11-20T08:27:26.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2633,
                salon_id: 1,
                specified_id: 1,
                service_id: 788,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:16:51.000000Z",
                updated_at: "2022-12-11T12:16:51.000000Z",
                deleted_at: null,
                service: {
                    id: 788,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-19T15:41:59.000000Z",
                    updated_at: "2022-11-20T09:53:04.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1575,
                        service_id: 788,
                        name: "Hand gel manicure removal with pedicure",
                        lang: "en",
                        created_at: "2022-11-19T15:41:59.000000Z",
                        updated_at: "2022-11-19T15:41:59.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1576,
                        service_id: 788,
                        name: "إزالة مناكير جل لليدين مع بديكير",
                        lang: "ar",
                        created_at: "2022-11-19T15:41:59.000000Z",
                        updated_at: "2022-11-19T15:41:59.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2634,
                salon_id: 1,
                specified_id: 1,
                service_id: 787,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:16:51.000000Z",
                updated_at: "2022-12-11T12:16:51.000000Z",
                deleted_at: null,
                service: {
                    id: 787,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-19T15:41:03.000000Z",
                    updated_at: "2022-11-20T09:50:04.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1573,
                        service_id: 787,
                        name: "French nail color",
                        lang: "en",
                        created_at: "2022-11-19T15:41:03.000000Z",
                        updated_at: "2022-11-19T15:41:03.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1574,
                        service_id: 787,
                        name: "لون أظافر فرنسي",
                        lang: "ar",
                        created_at: "2022-11-19T15:41:03.000000Z",
                        updated_at: "2022-11-19T15:41:03.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2635,
                salon_id: 1,
                specified_id: 1,
                service_id: 742,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:17:22.000000Z",
                updated_at: "2022-12-11T12:17:22.000000Z",
                deleted_at: null,
                service: {
                    id: 742,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-16T10:15:21.000000Z",
                    updated_at: "2022-11-16T10:15:35.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1483,
                        service_id: 742,
                        name: "Pedicure treatment  | for the feet",
                        lang: "en",
                        created_at: "2022-11-16T10:15:21.000000Z",
                        updated_at: "2022-11-16T10:15:21.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1484,
                        service_id: 742,
                        name: "بديكير علاجي | للقدمين",
                        lang: "ar",
                        created_at: "2022-11-16T10:15:21.000000Z",
                        updated_at: "2022-11-16T10:15:21.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2636,
                salon_id: 1,
                specified_id: 1,
                service_id: 741,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:17:22.000000Z",
                updated_at: "2022-12-11T12:17:22.000000Z",
                deleted_at: null,
                service: {
                    id: 741,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-16T10:14:39.000000Z",
                    updated_at: "2022-11-16T10:14:39.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1481,
                        service_id: 741,
                        name: "Pedicure treatment | for hands",
                        lang: "en",
                        created_at: "2022-11-16T10:14:39.000000Z",
                        updated_at: "2022-11-16T10:14:39.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1482,
                        service_id: 741,
                        name: "بديكير علاجي | لليدين",
                        lang: "ar",
                        created_at: "2022-11-16T10:14:39.000000Z",
                        updated_at: "2022-11-16T10:14:39.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2637,
                salon_id: 1,
                specified_id: 1,
                service_id: 740,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:17:22.000000Z",
                updated_at: "2022-12-11T12:17:22.000000Z",
                deleted_at: null,
                service: {
                    id: 740,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-16T10:14:00.000000Z",
                    updated_at: "2022-11-16T10:14:00.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1479,
                        service_id: 740,
                        name: "Hand + foot pedicure treatment",
                        lang: "en",
                        created_at: "2022-11-16T10:14:00.000000Z",
                        updated_at: "2022-11-16T10:14:00.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1480,
                        service_id: 740,
                        name: "بديكير علاجي لليدين + للقدمين",
                        lang: "ar",
                        created_at: "2022-11-16T10:14:00.000000Z",
                        updated_at: "2022-11-16T10:14:00.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2638,
                salon_id: 1,
                specified_id: 1,
                service_id: 715,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:17:49.000000Z",
                updated_at: "2022-12-11T12:17:49.000000Z",
                deleted_at: null,
                service: {
                    id: 715,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-16T08:22:24.000000Z",
                    updated_at: "2022-11-16T08:22:24.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1429,
                        service_id: 715,
                        name: "VIP gel pedicure",
                        lang: "en",
                        created_at: "2022-11-16T08:22:24.000000Z",
                        updated_at: "2022-11-16T08:22:24.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1430,
                        service_id: 715,
                        name: "بديكير جل في اي بي",
                        lang: "ar",
                        created_at: "2022-11-16T08:22:24.000000Z",
                        updated_at: "2022-11-16T08:22:24.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2639,
                salon_id: 1,
                specified_id: 1,
                service_id: 714,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:17:49.000000Z",
                updated_at: "2022-12-11T12:17:49.000000Z",
                deleted_at: null,
                service: {
                    id: 714,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-16T08:21:46.000000Z",
                    updated_at: "2022-11-16T08:21:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1427,
                        service_id: 714,
                        name: "VIP pedicure",
                        lang: "en",
                        created_at: "2022-11-16T08:21:46.000000Z",
                        updated_at: "2022-11-16T08:21:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1428,
                        service_id: 714,
                        name: "بديكير في اي بي علاجي",
                        lang: "ar",
                        created_at: "2022-11-16T08:21:46.000000Z",
                        updated_at: "2022-11-16T08:21:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2640,
                salon_id: 1,
                specified_id: 1,
                service_id: 336,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:18:20.000000Z",
                updated_at: "2022-12-11T12:18:20.000000Z",
                deleted_at: null,
                service: {
                    id: 336,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-28T11:38:39.000000Z",
                    updated_at: "2021-12-28T11:38:39.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 671,
                        service_id: 336,
                        name: "Manicure & pedicure for Kids",
                        lang: "en",
                        created_at: "2021-12-28T11:38:39.000000Z",
                        updated_at: "2021-12-28T11:38:39.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 672,
                        service_id: 336,
                        name: "بديكير  مناكير الأطفال",
                        lang: "ar",
                        created_at: "2021-12-28T11:38:39.000000Z",
                        updated_at: "2021-12-28T11:38:39.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2641,
                salon_id: 1,
                specified_id: 1,
                service_id: 363,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:18:20.000000Z",
                updated_at: "2022-12-11T12:18:20.000000Z",
                deleted_at: null,
                service: {
                    id: 363,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-29T13:10:15.000000Z",
                    updated_at: "2021-12-29T13:10:15.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 725,
                        service_id: 363,
                        name: "Classic manicure",
                        lang: "en",
                        created_at: "2021-12-29T13:10:15.000000Z",
                        updated_at: "2021-12-29T13:10:15.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 726,
                        service_id: 363,
                        name: "مناكير كلاسيك",
                        lang: "ar",
                        created_at: "2021-12-29T13:10:15.000000Z",
                        updated_at: "2021-12-29T13:10:15.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2642,
                salon_id: 1,
                specified_id: 1,
                service_id: 366,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:18:20.000000Z",
                updated_at: "2022-12-11T12:18:20.000000Z",
                deleted_at: null,
                service: {
                    id: 366,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-29T13:26:43.000000Z",
                    updated_at: "2021-12-29T13:26:43.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 731,
                        service_id: 366,
                        name: "file nails with color",
                        lang: "en",
                        created_at: "2021-12-29T13:26:43.000000Z",
                        updated_at: "2021-12-29T13:26:43.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 732,
                        service_id: 366,
                        name: "برد ولون",
                        lang: "ar",
                        created_at: "2021-12-29T13:26:43.000000Z",
                        updated_at: "2021-12-29T13:26:43.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2643,
                salon_id: 1,
                specified_id: 1,
                service_id: 367,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:18:20.000000Z",
                updated_at: "2022-12-11T12:18:20.000000Z",
                deleted_at: null,
                service: {
                    id: 367,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-29T13:27:11.000000Z",
                    updated_at: "2021-12-29T13:27:11.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 733,
                        service_id: 367,
                        name: "poly gel",
                        lang: "en",
                        created_at: "2021-12-29T13:27:11.000000Z",
                        updated_at: "2021-12-29T13:27:11.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 734,
                        service_id: 367,
                        name: "بولي جل",
                        lang: "ar",
                        created_at: "2021-12-29T13:27:11.000000Z",
                        updated_at: "2021-12-29T13:27:11.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2644,
                salon_id: 1,
                specified_id: 1,
                service_id: 388,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:18:20.000000Z",
                updated_at: "2022-12-11T12:18:20.000000Z",
                deleted_at: null,
                service: {
                    id: 388,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-01-03T15:51:39.000000Z",
                    updated_at: "2022-01-03T15:51:39.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 775,
                        service_id: 388,
                        name: "Add color for the hands",
                        lang: "en",
                        created_at: "2022-01-03T15:51:39.000000Z",
                        updated_at: "2022-01-03T15:51:39.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 776,
                        service_id: 388,
                        name: "إضافة لون لليدين",
                        lang: "ar",
                        created_at: "2022-01-03T15:51:39.000000Z",
                        updated_at: "2022-01-03T15:51:39.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2645,
                salon_id: 1,
                specified_id: 1,
                service_id: 491,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:19:18.000000Z",
                updated_at: "2022-12-11T12:19:18.000000Z",
                deleted_at: null,
                service: {
                    id: 491,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:39:18.000000Z",
                    updated_at: "2022-04-16T21:39:18.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 981,
                        service_id: 491,
                        name: "Light gel layer to protect nails",
                        lang: "en",
                        created_at: "2022-04-16T21:39:18.000000Z",
                        updated_at: "2022-04-16T21:39:18.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 982,
                        service_id: 491,
                        name: "طبقة جل خفيفة لحماية الأظافر",
                        lang: "ar",
                        created_at: "2022-04-16T21:39:18.000000Z",
                        updated_at: "2022-04-16T21:39:18.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2646,
                salon_id: 1,
                specified_id: 1,
                service_id: 492,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:19:18.000000Z",
                updated_at: "2022-12-11T12:19:18.000000Z",
                deleted_at: null,
                service: {
                    id: 492,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:39:47.000000Z",
                    updated_at: "2022-04-16T21:39:47.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 983,
                        service_id: 492,
                        name: "Cut and file fingernails",
                        lang: "en",
                        created_at: "2022-04-16T21:39:47.000000Z",
                        updated_at: "2022-04-16T21:39:47.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 984,
                        service_id: 492,
                        name: "قص وبرد أظافر اليدين",
                        lang: "ar",
                        created_at: "2022-04-16T21:39:47.000000Z",
                        updated_at: "2022-04-16T21:39:47.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2647,
                salon_id: 1,
                specified_id: 1,
                service_id: 493,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:19:18.000000Z",
                updated_at: "2022-12-11T12:19:18.000000Z",
                deleted_at: null,
                service: {
                    id: 493,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:40:29.000000Z",
                    updated_at: "2022-04-16T21:40:29.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 985,
                        service_id: 493,
                        name: "Cut and file toenails",
                        lang: "en",
                        created_at: "2022-04-16T21:40:29.000000Z",
                        updated_at: "2022-04-16T21:40:29.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 986,
                        service_id: 493,
                        name: "قص وبرد أظافر القدمين",
                        lang: "ar",
                        created_at: "2022-04-16T21:40:29.000000Z",
                        updated_at: "2022-04-16T21:40:29.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2648,
                salon_id: 1,
                specified_id: 1,
                service_id: 494,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:19:18.000000Z",
                updated_at: "2022-12-11T12:19:18.000000Z",
                deleted_at: null,
                service: {
                    id: 494,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:41:00.000000Z",
                    updated_at: "2022-04-16T21:41:00.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 987,
                        service_id: 494,
                        name: "Plain chrome powder",
                        lang: "en",
                        created_at: "2022-04-16T21:41:00.000000Z",
                        updated_at: "2022-04-16T21:41:00.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 988,
                        service_id: 494,
                        name: "كروم باودر عادي",
                        lang: "ar",
                        created_at: "2022-04-16T21:41:00.000000Z",
                        updated_at: "2022-04-16T21:41:00.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2649,
                salon_id: 1,
                specified_id: 1,
                service_id: 495,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:19:18.000000Z",
                updated_at: "2022-12-11T12:19:18.000000Z",
                deleted_at: null,
                service: {
                    id: 495,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:49:58.000000Z",
                    updated_at: "2022-04-16T21:49:58.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 989,
                        service_id: 495,
                        name: "Paraffin Angel for Hands Treatment",
                        lang: "en",
                        created_at: "2022-04-16T21:49:58.000000Z",
                        updated_at: "2022-04-16T21:49:58.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 990,
                        service_id: 495,
                        name: "علاج بارافين انجل هاندز لليدين",
                        lang: "ar",
                        created_at: "2022-04-16T21:49:58.000000Z",
                        updated_at: "2022-04-16T21:49:58.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2650,
                salon_id: 1,
                specified_id: 1,
                service_id: 496,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:19:18.000000Z",
                updated_at: "2022-12-11T12:19:18.000000Z",
                deleted_at: null,
                service: {
                    id: 496,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:50:55.000000Z",
                    updated_at: "2022-04-16T21:50:55.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 991,
                        service_id: 496,
                        name: "Paraffin Angel Hands for Foot Treatment",
                        lang: "en",
                        created_at: "2022-04-16T21:50:55.000000Z",
                        updated_at: "2022-04-16T21:50:55.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 992,
                        service_id: 496,
                        name: "علاج بارافين انجل هاندز للقدمين",
                        lang: "ar",
                        created_at: "2022-04-16T21:50:55.000000Z",
                        updated_at: "2022-04-16T21:50:55.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2651,
                salon_id: 1,
                specified_id: 1,
                service_id: 498,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:19:18.000000Z",
                updated_at: "2022-12-11T12:19:18.000000Z",
                deleted_at: null,
                service: {
                    id: 498,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:52:00.000000Z",
                    updated_at: "2022-04-16T21:52:00.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 995,
                        service_id: 498,
                        name: "Gold treatment for feet",
                        lang: "en",
                        created_at: "2022-04-16T21:52:00.000000Z",
                        updated_at: "2022-04-16T21:52:00.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 996,
                        service_id: 498,
                        name: "علاج الذهب للقدمين",
                        lang: "ar",
                        created_at: "2022-04-16T21:52:00.000000Z",
                        updated_at: "2022-04-16T21:52:00.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2652,
                salon_id: 1,
                specified_id: 1,
                service_id: 497,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:19:18.000000Z",
                updated_at: "2022-12-11T12:19:18.000000Z",
                deleted_at: null,
                service: {
                    id: 497,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:51:30.000000Z",
                    updated_at: "2022-04-16T21:51:30.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 993,
                        service_id: 497,
                        name: "Gold treatment for hands",
                        lang: "en",
                        created_at: "2022-04-16T21:51:30.000000Z",
                        updated_at: "2022-04-16T21:51:30.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 994,
                        service_id: 497,
                        name: "علاج الذهب لليدين",
                        lang: "ar",
                        created_at: "2022-04-16T21:51:30.000000Z",
                        updated_at: "2022-04-16T21:51:30.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2653,
                salon_id: 1,
                specified_id: 1,
                service_id: 499,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:19:18.000000Z",
                updated_at: "2022-12-11T12:19:18.000000Z",
                deleted_at: null,
                service: {
                    id: 499,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:53:01.000000Z",
                    updated_at: "2022-04-16T21:53:29.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 997,
                        service_id: 499,
                        name: "Angel Hands Set for Hand and Feet Care",
                        lang: "en",
                        created_at: "2022-04-16T21:53:01.000000Z",
                        updated_at: "2022-04-16T21:53:29.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 998,
                        service_id: 499,
                        name: "مجموعة انجل هاندز للعناية باليدين والقدمين",
                        lang: "ar",
                        created_at: "2022-04-16T21:53:01.000000Z",
                        updated_at: "2022-04-16T21:53:01.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2654,
                salon_id: 1,
                specified_id: 1,
                service_id: 500,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:19:18.000000Z",
                updated_at: "2022-12-11T12:19:18.000000Z",
                deleted_at: null,
                service: {
                    id: 500,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:54:03.000000Z",
                    updated_at: "2022-04-16T21:54:03.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 999,
                        service_id: 500,
                        name: "Detox set for hands and feet",
                        lang: "en",
                        created_at: "2022-04-16T21:54:03.000000Z",
                        updated_at: "2022-04-16T21:54:03.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1000,
                        service_id: 500,
                        name: "مجموعة الديتوكس للعناية باليدين والقدمين",
                        lang: "ar",
                        created_at: "2022-04-16T21:54:03.000000Z",
                        updated_at: "2022-04-16T21:54:03.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2699,
                salon_id: 1,
                specified_id: 1,
                service_id: 11,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:32:18.000000Z",
                updated_at: "2022-12-11T12:32:18.000000Z",
                deleted_at: null,
                service: {
                    id: 11,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 21,
                        service_id: 11,
                        name: "french manicure",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 22,
                        service_id: 11,
                        name: "مناكير فرنسي",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2700,
                salon_id: 1,
                specified_id: 1,
                service_id: 12,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:32:18.000000Z",
                updated_at: "2022-12-11T12:32:18.000000Z",
                deleted_at: null,
                service: {
                    id: 12,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 23,
                        service_id: 12,
                        name: "french manicure for hands",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 24,
                        service_id: 12,
                        name: "مناكير فرنسي لليدين",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2935,
                salon_id: 1,
                specified_id: 1,
                service_id: 636,
                services_time_id: 9,
                amount: "44.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2023-01-15T10:52:40.000000Z",
                updated_at: "2023-01-15T10:52:40.000000Z",
                deleted_at: null,
                service: {
                    id: 636,
                    category_id: 1,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-15T07:28:00.000000Z",
                    updated_at: "2022-11-15T07:28:00.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 9,
                    time: 40,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:40:01.000000Z",
                    updated_at: "2021-12-23T17:40:01.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1271,
                        service_id: 636,
                        name: "Paraffin for hands",
                        lang: "en",
                        created_at: "2022-11-15T07:28:00.000000Z",
                        updated_at: "2022-11-15T07:28:00.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1272,
                        service_id: 636,
                        name: "برافين لليدين",
                        lang: "ar",
                        created_at: "2022-11-15T07:28:00.000000Z",
                        updated_at: "2022-11-15T07:28:00.000000Z",
                        deleted_at: null
                    }
                }
            }
        ],
        2: [
            {
                id: 5,
                salon_id: 1,
                specified_id: 1,
                service_id: 22,
                services_time_id: 4,
                amount: "50.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:30:12.000000Z",
                updated_at: "2022-12-01T10:20:49.000000Z",
                deleted_at: null,
                service: {
                    id: 22,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 43,
                        service_id: 22,
                        name: "One color | medium",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 44,
                        service_id: 22,
                        name: "صبغة لون واحد | متوسط",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 6,
                salon_id: 1,
                specified_id: 1,
                service_id: 23,
                services_time_id: 4,
                amount: "60.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:30:12.000000Z",
                updated_at: "2022-12-01T10:20:49.000000Z",
                deleted_at: null,
                service: {
                    id: 23,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 45,
                        service_id: 23,
                        name: "One color | long",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 46,
                        service_id: 23,
                        name: "صبغة لون واحد | طويل",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 1109,
                salon_id: 1,
                specified_id: 1,
                service_id: 25,
                services_time_id: 7,
                amount: "999.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-06-19T13:58:48.000000Z",
                updated_at: "2022-12-01T10:20:49.000000Z",
                deleted_at: null,
                service: {
                    id: 25,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 7,
                    time: 30,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:39:08.000000Z",
                    updated_at: "2021-12-23T17:39:08.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 49,
                        service_id: 25,
                        name: "Hair color with ammonia | medium",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 50,
                        service_id: 25,
                        name: "صبغة لون واحد مع الأمونيا| متوسط",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 1385,
                salon_id: 1,
                specified_id: 1,
                service_id: 26,
                services_time_id: 2,
                amount: "55.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-10-12T06:10:54.000000Z",
                updated_at: "2022-12-01T10:20:49.000000Z",
                deleted_at: null,
                service: {
                    id: 26,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 51,
                        service_id: 26,
                        name: "Hair color with ammonia | long",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 52,
                        service_id: 26,
                        name: "صبغة لون واحد مع الأمونيا | طويل",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 1778,
                salon_id: 1,
                specified_id: 1,
                service_id: 38,
                services_time_id: 2,
                amount: "30.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-11-19T13:53:14.000000Z",
                updated_at: "2022-12-01T10:20:49.000000Z",
                deleted_at: null,
                service: {
                    id: 38,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 75,
                        service_id: 38,
                        name: "High & low light  | long",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 76,
                        service_id: 38,
                        name: "صبغة خصل لايت لولايت | طويل",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2655,
                salon_id: 1,
                specified_id: 1,
                service_id: 21,
                services_time_id: 3,
                amount: "5.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:20:39.000000Z",
                updated_at: "2023-01-17T09:56:46.000000Z",
                deleted_at: null,
                service: {
                    id: 21,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 41,
                        service_id: 21,
                        name: "One color | short",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 42,
                        service_id: 21,
                        name: "صبغة لون واحد | قصير",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2656,
                salon_id: 1,
                specified_id: 1,
                service_id: 24,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:20:39.000000Z",
                updated_at: "2022-12-11T12:20:39.000000Z",
                deleted_at: null,
                service: {
                    id: 24,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 47,
                        service_id: 24,
                        name: "Hair color with ammonia | short",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 48,
                        service_id: 24,
                        name: "صبغة لون واحد مع الأمونيا | قصير",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2657,
                salon_id: 1,
                specified_id: 1,
                service_id: 30,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:20:54.000000Z",
                updated_at: "2022-12-11T12:20:54.000000Z",
                deleted_at: null,
                service: {
                    id: 30,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 59,
                        service_id: 30,
                        name: "Hair color with bleaching | short",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 60,
                        service_id: 30,
                        name: "صبغة لون واحد مع سحب لون | قصير",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2658,
                salon_id: 1,
                specified_id: 1,
                service_id: 29,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:20:54.000000Z",
                updated_at: "2022-12-11T12:20:54.000000Z",
                deleted_at: null,
                service: {
                    id: 29,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 57,
                        service_id: 29,
                        name: "Hair color with ammonia-free | long",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 58,
                        service_id: 29,
                        name: "صبغة لون واحد خالية من الامونيا | طويل",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2659,
                salon_id: 1,
                specified_id: 1,
                service_id: 33,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:21:16.000000Z",
                updated_at: "2022-12-11T12:21:16.000000Z",
                deleted_at: null,
                service: {
                    id: 33,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 65,
                        service_id: 33,
                        name: "Hair color without bleaching | short",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 66,
                        service_id: 33,
                        name: "صبغة لون واحد بدون سحب لون | قصير",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2660,
                salon_id: 1,
                specified_id: 1,
                service_id: 34,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:21:16.000000Z",
                updated_at: "2022-12-11T12:21:16.000000Z",
                deleted_at: null,
                service: {
                    id: 34,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 67,
                        service_id: 34,
                        name: "Hair color without bleaching | medium",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 68,
                        service_id: 34,
                        name: "صبغة لون واحد بدون سحب لون | متوسط",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2661,
                salon_id: 1,
                specified_id: 1,
                service_id: 37,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:21:35.000000Z",
                updated_at: "2022-12-11T12:21:35.000000Z",
                deleted_at: null,
                service: {
                    id: 37,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 73,
                        service_id: 37,
                        name: "High & low light  | medium",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 74,
                        service_id: 37,
                        name: "صبغة خصل لايت لولايت | متوسط",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2662,
                salon_id: 1,
                specified_id: 1,
                service_id: 36,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:21:35.000000Z",
                updated_at: "2022-12-11T12:21:35.000000Z",
                deleted_at: null,
                service: {
                    id: 36,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 71,
                        service_id: 36,
                        name: "High & low light  | short",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 72,
                        service_id: 36,
                        name: "صبغة خصل لايت لولايت | قصير",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2663,
                salon_id: 1,
                specified_id: 1,
                service_id: 39,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:21:35.000000Z",
                updated_at: "2022-12-11T12:21:35.000000Z",
                deleted_at: null,
                service: {
                    id: 39,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 77,
                        service_id: 39,
                        name: "MAJIREL Hair color | short",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 78,
                        service_id: 39,
                        name: "الوان الماجاريل | قصير",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2664,
                salon_id: 1,
                specified_id: 1,
                service_id: 42,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:07.000000Z",
                updated_at: "2022-12-11T12:22:07.000000Z",
                deleted_at: null,
                service: {
                    id: 42,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 83,
                        service_id: 42,
                        name: "Toner | short",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 84,
                        service_id: 42,
                        name: "تفتيح لون | قصير",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2665,
                salon_id: 1,
                specified_id: 1,
                service_id: 41,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:07.000000Z",
                updated_at: "2022-12-11T12:22:07.000000Z",
                deleted_at: null,
                service: {
                    id: 41,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 81,
                        service_id: 41,
                        name: "MAJIREL Hair color | long",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 82,
                        service_id: 41,
                        name: "الوان الماجاريل | طويل",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2666,
                salon_id: 1,
                specified_id: 1,
                service_id: 40,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:07.000000Z",
                updated_at: "2022-12-11T12:22:07.000000Z",
                deleted_at: null,
                service: {
                    id: 40,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 79,
                        service_id: 40,
                        name: "MAJIREL Hair color | medium",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 80,
                        service_id: 40,
                        name: "الوان الماجاريل | متوسط",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2667,
                salon_id: 1,
                specified_id: 1,
                service_id: 43,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:07.000000Z",
                updated_at: "2022-12-11T12:22:07.000000Z",
                deleted_at: null,
                service: {
                    id: 43,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 85,
                        service_id: 43,
                        name: "Toner | medium",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 86,
                        service_id: 43,
                        name: "تفتيح لون | متوسط",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2668,
                salon_id: 1,
                specified_id: 1,
                service_id: 44,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:07.000000Z",
                updated_at: "2022-12-11T12:22:07.000000Z",
                deleted_at: null,
                service: {
                    id: 44,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 87,
                        service_id: 44,
                        name: "Toner | long",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 88,
                        service_id: 44,
                        name: "تفتيح لون | طويل",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2669,
                salon_id: 1,
                specified_id: 1,
                service_id: 55,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:57.000000Z",
                updated_at: "2022-12-11T12:22:57.000000Z",
                deleted_at: null,
                service: {
                    id: 55,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 109,
                        service_id: 55,
                        name: "Ombre with color | short",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 110,
                        service_id: 55,
                        name: "أومبري | قصير",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2670,
                salon_id: 1,
                specified_id: 1,
                service_id: 56,
                services_time_id: 4,
                amount: "11.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:57.000000Z",
                updated_at: "2022-12-11T12:22:57.000000Z",
                deleted_at: null,
                service: {
                    id: 56,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 111,
                        service_id: 56,
                        name: "Ombre with color | medium",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 112,
                        service_id: 56,
                        name: "أومبري | متوسط",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2671,
                salon_id: 1,
                specified_id: 1,
                service_id: 57,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:57.000000Z",
                updated_at: "2022-12-11T12:22:57.000000Z",
                deleted_at: null,
                service: {
                    id: 57,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 113,
                        service_id: 57,
                        name: "Ombre with color | long",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 114,
                        service_id: 57,
                        name: "أومبري | طويل",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2672,
                salon_id: 1,
                specified_id: 1,
                service_id: 58,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:57.000000Z",
                updated_at: "2022-12-11T12:22:57.000000Z",
                deleted_at: null,
                service: {
                    id: 58,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 115,
                        service_id: 58,
                        name: "Highlight | short",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 116,
                        service_id: 58,
                        name: "هاي لايت | قصير",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2673,
                salon_id: 1,
                specified_id: 1,
                service_id: 59,
                services_time_id: 1,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:57.000000Z",
                updated_at: "2022-12-11T12:22:57.000000Z",
                deleted_at: null,
                service: {
                    id: 59,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 1,
                    time: 1,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:09:37.000000Z",
                    updated_at: "2021-12-23T17:09:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 117,
                        service_id: 59,
                        name: "Highlight|  medium",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 118,
                        service_id: 59,
                        name: "هاي لايت | متوسط",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2674,
                salon_id: 1,
                specified_id: 1,
                service_id: 60,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:57.000000Z",
                updated_at: "2022-12-11T12:22:57.000000Z",
                deleted_at: null,
                service: {
                    id: 60,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 119,
                        service_id: 60,
                        name: "Highlight | long",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 120,
                        service_id: 60,
                        name: "هاي لايت | طويل",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2675,
                salon_id: 1,
                specified_id: 1,
                service_id: 61,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:57.000000Z",
                updated_at: "2022-12-11T12:22:57.000000Z",
                deleted_at: null,
                service: {
                    id: 61,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 121,
                        service_id: 61,
                        name: "INOA color |  short",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 122,
                        service_id: 61,
                        name: "الوان الانوا | قصير",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2676,
                salon_id: 1,
                specified_id: 1,
                service_id: 62,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:57.000000Z",
                updated_at: "2022-12-11T12:22:57.000000Z",
                deleted_at: null,
                service: {
                    id: 62,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 123,
                        service_id: 62,
                        name: "INOA color | medium",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 124,
                        service_id: 62,
                        name: "الوان الانوا | متوسط",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2677,
                salon_id: 1,
                specified_id: 1,
                service_id: 63,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:22:57.000000Z",
                updated_at: "2022-12-11T12:22:57.000000Z",
                deleted_at: null,
                service: {
                    id: 63,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 125,
                        service_id: 63,
                        name: "INOA color | long",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 126,
                        service_id: 63,
                        name: "الوان الانوا | طويل",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2678,
                salon_id: 1,
                specified_id: 1,
                service_id: 389,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:24:02.000000Z",
                updated_at: "2022-12-11T12:24:02.000000Z",
                deleted_at: null,
                service: {
                    id: 389,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-01-03T15:56:03.000000Z",
                    updated_at: "2022-01-03T15:56:03.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 777,
                        service_id: 389,
                        name: "Rensaj",
                        lang: "en",
                        created_at: "2022-01-03T15:56:03.000000Z",
                        updated_at: "2022-01-03T15:56:03.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 778,
                        service_id: 389,
                        name: "رنساج",
                        lang: "ar",
                        created_at: "2022-01-03T15:56:03.000000Z",
                        updated_at: "2022-01-03T15:56:03.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2679,
                salon_id: 1,
                specified_id: 1,
                service_id: 390,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:24:02.000000Z",
                updated_at: "2022-12-11T12:24:02.000000Z",
                deleted_at: null,
                service: {
                    id: 390,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-01-03T15:56:49.000000Z",
                    updated_at: "2022-01-03T15:56:49.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 779,
                        service_id: 390,
                        name: "Roots tincture",
                        lang: "en",
                        created_at: "2022-01-03T15:56:49.000000Z",
                        updated_at: "2022-01-03T15:56:49.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 780,
                        service_id: 390,
                        name: "صبغة الجذور",
                        lang: "ar",
                        created_at: "2022-01-03T15:56:49.000000Z",
                        updated_at: "2022-01-03T15:56:49.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2680,
                salon_id: 1,
                specified_id: 1,
                service_id: 406,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:24:43.000000Z",
                updated_at: "2022-12-11T12:24:43.000000Z",
                deleted_at: null,
                service: {
                    id: 406,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-02-20T14:03:44.000000Z",
                    updated_at: "2022-02-20T14:03:44.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 811,
                        service_id: 406,
                        name: "Organic Roots | Short",
                        lang: "en",
                        created_at: "2022-02-20T14:03:44.000000Z",
                        updated_at: "2022-02-20T14:03:44.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 812,
                        service_id: 406,
                        name: "صبغة عضوي | قصير",
                        lang: "ar",
                        created_at: "2022-02-20T14:03:44.000000Z",
                        updated_at: "2022-02-20T14:03:44.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2681,
                salon_id: 1,
                specified_id: 1,
                service_id: 915,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:24:43.000000Z",
                updated_at: "2022-12-11T12:24:43.000000Z",
                deleted_at: null,
                service: {
                    id: 915,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-28T06:35:14.000000Z",
                    updated_at: "2022-11-28T06:35:14.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1829,
                        service_id: 915,
                        name: "Hair dye | short",
                        lang: "en",
                        created_at: "2022-11-28T06:35:14.000000Z",
                        updated_at: "2022-11-28T06:35:14.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1830,
                        service_id: 915,
                        name: "صبغة شعر | قصير",
                        lang: "ar",
                        created_at: "2022-11-28T06:35:14.000000Z",
                        updated_at: "2022-11-28T06:35:14.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2682,
                salon_id: 1,
                specified_id: 1,
                service_id: 412,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:25:03.000000Z",
                updated_at: "2022-12-11T12:25:03.000000Z",
                deleted_at: null,
                service: {
                    id: 412,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-02-20T14:12:25.000000Z",
                    updated_at: "2022-02-20T14:12:25.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 823,
                        service_id: 412,
                        name: "Organic Ombre | Short",
                        lang: "en",
                        created_at: "2022-02-20T14:12:25.000000Z",
                        updated_at: "2022-02-20T14:12:25.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 824,
                        service_id: 412,
                        name: "أومبري عضوي  | قصير",
                        lang: "ar",
                        created_at: "2022-02-20T14:12:25.000000Z",
                        updated_at: "2022-02-20T14:12:25.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2683,
                salon_id: 1,
                specified_id: 1,
                service_id: 413,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:25:03.000000Z",
                updated_at: "2022-12-11T12:25:03.000000Z",
                deleted_at: null,
                service: {
                    id: 413,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-02-20T14:13:01.000000Z",
                    updated_at: "2022-02-20T14:13:01.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 825,
                        service_id: 413,
                        name: "Organic Ombre | Medium",
                        lang: "en",
                        created_at: "2022-02-20T14:13:01.000000Z",
                        updated_at: "2022-02-20T14:13:01.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 826,
                        service_id: 413,
                        name: "أومبري عضوي | متوسط",
                        lang: "ar",
                        created_at: "2022-02-20T14:13:01.000000Z",
                        updated_at: "2022-02-20T14:13:01.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2937,
                salon_id: 1,
                specified_id: 1,
                service_id: 27,
                services_time_id: 9,
                amount: "55.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2023-01-17T09:56:27.000000Z",
                updated_at: "2023-01-17T09:56:27.000000Z",
                deleted_at: null,
                service: {
                    id: 27,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 9,
                    time: 40,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:40:01.000000Z",
                    updated_at: "2021-12-23T17:40:01.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 53,
                        service_id: 27,
                        name: "Hair color with ammonia-free | short",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 54,
                        service_id: 27,
                        name: "صبغة لون واحد خالية من الأمونيا | قصير",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2938,
                salon_id: 1,
                specified_id: 1,
                service_id: 31,
                services_time_id: 3,
                amount: "100.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2023-01-17T09:56:27.000000Z",
                updated_at: "2023-01-17T09:56:27.000000Z",
                deleted_at: null,
                service: {
                    id: 31,
                    category_id: 2,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 61,
                        service_id: 31,
                        name: "Hair color with bleaching | medium",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 62,
                        service_id: 31,
                        name: "صبغة لون واحد مع سحب لون | متوسط",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            }
        ],
        3: [
            {
                id: 7,
                salon_id: 1,
                specified_id: 1,
                service_id: 68,
                services_time_id: 4,
                amount: "50.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:30:12.000000Z",
                updated_at: "2022-12-01T10:20:49.000000Z",
                deleted_at: null,
                service: {
                    id: 68,
                    category_id: 3,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 135,
                        service_id: 68,
                        name: "Deep clean facial cleansing",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 136,
                        service_id: 68,
                        name: "تنظيف البشرة العميق",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 1310,
                salon_id: 1,
                specified_id: 1,
                service_id: 487,
                services_time_id: 4,
                amount: "90.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-08-22T10:31:49.000000Z",
                updated_at: "2022-12-01T10:20:49.000000Z",
                deleted_at: null,
                service: {
                    id: 487,
                    category_id: 3,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:36:51.000000Z",
                    updated_at: "2022-04-16T21:36:51.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 973,
                        service_id: 487,
                        name: "Classic Facial",
                        lang: "en",
                        created_at: "2022-04-16T21:36:51.000000Z",
                        updated_at: "2022-04-16T21:36:51.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 974,
                        service_id: 487,
                        name: "فيشل كلاسيك",
                        lang: "ar",
                        created_at: "2022-04-16T21:36:51.000000Z",
                        updated_at: "2022-04-16T21:36:51.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 1467,
                salon_id: 1,
                specified_id: 1,
                service_id: 488,
                services_time_id: 4,
                amount: "90.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-10-23T07:51:52.000000Z",
                updated_at: "2022-12-01T10:20:49.000000Z",
                deleted_at: null,
                service: {
                    id: 488,
                    category_id: 3,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-04-16T21:37:20.000000Z",
                    updated_at: "2022-04-16T21:37:20.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 975,
                        service_id: 488,
                        name: "Facial Special Angel",
                        lang: "en",
                        created_at: "2022-04-16T21:37:20.000000Z",
                        updated_at: "2022-04-16T21:37:20.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 976,
                        service_id: 488,
                        name: "فيشل سبيشل انجل",
                        lang: "ar",
                        created_at: "2022-04-16T21:37:20.000000Z",
                        updated_at: "2022-04-16T21:37:20.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2684,
                salon_id: 1,
                specified_id: 1,
                service_id: 351,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:25:29.000000Z",
                updated_at: "2022-12-11T12:25:29.000000Z",
                deleted_at: null,
                service: {
                    id: 351,
                    category_id: 3,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-28T12:03:38.000000Z",
                    updated_at: "2021-12-28T12:03:38.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 701,
                        service_id: 351,
                        name: "Classic facial cleansing",
                        lang: "en",
                        created_at: "2021-12-28T12:03:38.000000Z",
                        updated_at: "2021-12-28T12:03:38.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 702,
                        service_id: 351,
                        name: "تنظيف بشرة كلاسيك",
                        lang: "ar",
                        created_at: "2021-12-28T12:03:38.000000Z",
                        updated_at: "2021-12-28T12:03:38.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2685,
                salon_id: 1,
                specified_id: 1,
                service_id: 352,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:25:29.000000Z",
                updated_at: "2022-12-11T12:25:29.000000Z",
                deleted_at: null,
                service: {
                    id: 352,
                    category_id: 3,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-28T12:04:06.000000Z",
                    updated_at: "2021-12-28T12:04:06.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 703,
                        service_id: 352,
                        name: "Deep clean facial cleansing",
                        lang: "en",
                        created_at: "2021-12-28T12:04:06.000000Z",
                        updated_at: "2021-12-28T12:04:06.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 704,
                        service_id: 352,
                        name: "تنظيف البشرة العميق",
                        lang: "ar",
                        created_at: "2021-12-28T12:04:06.000000Z",
                        updated_at: "2021-12-28T12:04:06.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2686,
                salon_id: 1,
                specified_id: 1,
                service_id: 709,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:25:51.000000Z",
                updated_at: "2022-12-11T12:25:51.000000Z",
                deleted_at: null,
                service: {
                    id: 709,
                    category_id: 3,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-16T08:13:52.000000Z",
                    updated_at: "2022-11-16T08:13:52.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1417,
                        service_id: 709,
                        name: "Mini facial",
                        lang: "en",
                        created_at: "2022-11-16T08:13:52.000000Z",
                        updated_at: "2022-11-16T08:13:52.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1418,
                        service_id: 709,
                        name: "ميني فيشل",
                        lang: "ar",
                        created_at: "2022-11-16T08:13:52.000000Z",
                        updated_at: "2022-11-16T08:13:52.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2687,
                salon_id: 1,
                specified_id: 1,
                service_id: 688,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:25:51.000000Z",
                updated_at: "2022-12-11T12:25:51.000000Z",
                deleted_at: null,
                service: {
                    id: 688,
                    category_id: 3,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-16T07:33:38.000000Z",
                    updated_at: "2022-11-16T07:33:38.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1375,
                        service_id: 688,
                        name: "Gold mask",
                        lang: "en",
                        created_at: "2022-11-16T07:33:38.000000Z",
                        updated_at: "2022-11-16T07:33:38.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1376,
                        service_id: 688,
                        name: "ماسك الذهب",
                        lang: "ar",
                        created_at: "2022-11-16T07:33:38.000000Z",
                        updated_at: "2022-11-16T07:33:38.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2688,
                salon_id: 1,
                specified_id: 1,
                service_id: 687,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:26:05.000000Z",
                updated_at: "2022-12-11T12:26:05.000000Z",
                deleted_at: null,
                service: {
                    id: 687,
                    category_id: 3,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-16T07:33:08.000000Z",
                    updated_at: "2022-11-16T07:33:08.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1373,
                        service_id: 687,
                        name: "VIP Skin Cleaning with HydraFacial",
                        lang: "en",
                        created_at: "2022-11-16T07:33:08.000000Z",
                        updated_at: "2022-11-16T07:33:08.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1374,
                        service_id: 687,
                        name: "تنظيف بشرة VIP مع هايدرا فيشل",
                        lang: "ar",
                        created_at: "2022-11-16T07:33:08.000000Z",
                        updated_at: "2022-11-16T07:33:08.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2689,
                salon_id: 1,
                specified_id: 1,
                service_id: 768,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:26:05.000000Z",
                updated_at: "2022-12-11T12:26:05.000000Z",
                deleted_at: null,
                service: {
                    id: 768,
                    category_id: 3,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-16T11:39:24.000000Z",
                    updated_at: "2022-11-16T11:39:24.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1535,
                        service_id: 768,
                        name: "Body whitening cream with roses and Aker Fassi",
                        lang: "en",
                        created_at: "2022-11-16T11:39:24.000000Z",
                        updated_at: "2022-11-16T11:39:24.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1536,
                        service_id: 768,
                        name: "كريم تفتيح الجسم بالورد والعكر الفاسي",
                        lang: "ar",
                        created_at: "2022-11-16T11:39:24.000000Z",
                        updated_at: "2022-11-16T11:39:24.000000Z",
                        deleted_at: null
                    }
                }
            }
        ],
        4: [
            {
                id: 8,
                salon_id: 1,
                specified_id: 1,
                service_id: 69,
                services_time_id: 4,
                amount: "50.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:30:12.000000Z",
                updated_at: "2022-12-01T10:20:49.000000Z",
                deleted_at: null,
                service: {
                    id: 69,
                    category_id: 4,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 137,
                        service_id: 69,
                        name: "makeup",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 138,
                        service_id: 69,
                        name: "المكياج",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 9,
                salon_id: 1,
                specified_id: 1,
                service_id: 70,
                services_time_id: 4,
                amount: "50.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2021-12-23T17:30:12.000000Z",
                updated_at: "2022-12-01T10:20:49.000000Z",
                deleted_at: null,
                service: {
                    id: 70,
                    category_id: 4,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 139,
                        service_id: 70,
                        name: "Eyeliner",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 140,
                        service_id: 70,
                        name: "ايلاينر",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2690,
                salon_id: 1,
                specified_id: 1,
                service_id: 750,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:26:25.000000Z",
                updated_at: "2022-12-11T12:26:25.000000Z",
                deleted_at: null,
                service: {
                    id: 750,
                    category_id: 4,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-16T10:24:58.000000Z",
                    updated_at: "2022-11-16T10:24:58.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1499,
                        service_id: 750,
                        name: "Special Makeup",
                        lang: "en",
                        created_at: "2022-11-16T10:24:58.000000Z",
                        updated_at: "2022-11-16T10:24:58.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1500,
                        service_id: 750,
                        name: "مكياج سبيشال",
                        lang: "ar",
                        created_at: "2022-11-16T10:24:58.000000Z",
                        updated_at: "2022-11-16T10:24:58.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2691,
                salon_id: 1,
                specified_id: 1,
                service_id: 749,
                services_time_id: 3,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:26:25.000000Z",
                updated_at: "2022-12-11T12:26:25.000000Z",
                deleted_at: null,
                service: {
                    id: 749,
                    category_id: 4,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-16T10:22:33.000000Z",
                    updated_at: "2022-11-16T10:22:33.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1497,
                        service_id: 749,
                        name: "Upper body makeup only",
                        lang: "en",
                        created_at: "2022-11-16T10:22:33.000000Z",
                        updated_at: "2022-11-16T10:22:33.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1498,
                        service_id: 749,
                        name: "مكياج جسم علوي فقط",
                        lang: "ar",
                        created_at: "2022-11-16T10:22:33.000000Z",
                        updated_at: "2022-11-16T10:22:33.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2692,
                salon_id: 1,
                specified_id: 1,
                service_id: 72,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:30:31.000000Z",
                updated_at: "2022-12-11T12:30:31.000000Z",
                deleted_at: null,
                service: {
                    id: 72,
                    category_id: 4,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 143,
                        service_id: 72,
                        name: "Full makeup ",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 144,
                        service_id: 72,
                        name: "مكياج كامل",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2693,
                salon_id: 1,
                specified_id: 1,
                service_id: 71,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:30:31.000000Z",
                updated_at: "2022-12-11T12:30:31.000000Z",
                deleted_at: null,
                service: {
                    id: 71,
                    category_id: 4,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 141,
                        service_id: 71,
                        name: "Super Glam makeup",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 142,
                        service_id: 71,
                        name: "مكياج سهرة",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2694,
                salon_id: 1,
                specified_id: 1,
                service_id: 73,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:30:31.000000Z",
                updated_at: "2022-12-11T12:30:31.000000Z",
                deleted_at: null,
                service: {
                    id: 73,
                    category_id: 4,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 145,
                        service_id: 73,
                        name: "Bridel makeup",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 146,
                        service_id: 73,
                        name: "مكياج العروس",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2695,
                salon_id: 1,
                specified_id: 1,
                service_id: 74,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:30:31.000000Z",
                updated_at: "2022-12-11T12:30:31.000000Z",
                deleted_at: null,
                service: {
                    id: 74,
                    category_id: 4,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:46.000000Z",
                    updated_at: "2021-12-14T11:45:46.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 147,
                        service_id: 74,
                        name: "Eye makeup",
                        lang: "en",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 148,
                        service_id: 74,
                        name: "مكياج عيون",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:46.000000Z",
                        updated_at: "2021-12-14T11:45:46.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2696,
                salon_id: 1,
                specified_id: 1,
                service_id: 659,
                services_time_id: 2,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:30:31.000000Z",
                updated_at: "2022-12-11T12:30:31.000000Z",
                deleted_at: null,
                service: {
                    id: 659,
                    category_id: 4,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-11-15T08:27:58.000000Z",
                    updated_at: "2022-11-15T08:27:58.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 2,
                    time: 5,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:27:59.000000Z",
                    updated_at: "2021-12-23T17:27:59.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 1317,
                        service_id: 659,
                        name: "Lips blushing with lightning",
                        lang: "en",
                        created_at: "2022-11-15T08:27:58.000000Z",
                        updated_at: "2022-11-15T08:27:58.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 1318,
                        service_id: 659,
                        name: "توريد شفايف مع تفتيح",
                        lang: "ar",
                        created_at: "2022-11-15T08:27:58.000000Z",
                        updated_at: "2022-11-15T08:27:58.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2697,
                salon_id: 1,
                specified_id: 1,
                service_id: 385,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:30:32.000000Z",
                updated_at: "2022-12-11T12:30:32.000000Z",
                deleted_at: null,
                service: {
                    id: 385,
                    category_id: 4,
                    icon: null,
                    status: 1,
                    approved: 0,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2022-01-03T11:19:34.000000Z",
                    updated_at: "2022-01-03T11:19:34.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 769,
                        service_id: 385,
                        name: "Eyeliner",
                        lang: "en",
                        created_at: "2022-01-03T11:19:34.000000Z",
                        updated_at: "2022-01-03T11:19:34.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 770,
                        service_id: 385,
                        name: "ايلاينر",
                        lang: "ar",
                        created_at: "2022-01-03T11:19:34.000000Z",
                        updated_at: "2022-01-03T11:19:34.000000Z",
                        deleted_at: null
                    }
                }
            },
            {
                id: 2698,
                salon_id: 1,
                specified_id: 1,
                service_id: 362,
                services_time_id: 4,
                amount: "1.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-12-11T12:30:32.000000Z",
                updated_at: "2022-12-11T12:30:32.000000Z",
                deleted_at: null,
                service: {
                    id: 362,
                    category_id: 4,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-29T12:49:37.000000Z",
                    updated_at: "2021-12-29T12:49:37.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 4,
                    time: 15,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:37.000000Z",
                    updated_at: "2021-12-23T17:28:37.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 723,
                        service_id: 362,
                        name: "soft makeup",
                        lang: "en",
                        created_at: "2021-12-29T12:49:37.000000Z",
                        updated_at: "2021-12-29T12:49:37.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 724,
                        service_id: 362,
                        name: "مكياج ناعم",
                        lang: "ar",
                        created_at: "2021-12-29T12:49:37.000000Z",
                        updated_at: "2021-12-29T12:49:37.000000Z",
                        deleted_at: null
                    }
                }
            }
        ],
        13: [
            {
                id: 1309,
                salon_id: 1,
                specified_id: 1,
                service_id: 312,
                services_time_id: 3,
                amount: "55.00",
                created_by: 2,
                updated_by: 2,
                created_at: "2022-08-21T23:20:39.000000Z",
                updated_at: "2022-12-01T10:20:49.000000Z",
                deleted_at: null,
                service: {
                    id: 312,
                    category_id: 13,
                    icon: null,
                    status: 1,
                    approved: 1,
                    salon_id: 0,
                    created_by: 1,
                    updated_by: 1,
                    created_at: "2021-12-14T11:45:47.000000Z",
                    updated_at: "2021-12-14T11:45:47.000000Z",
                    deleted_at: null
                },
                services_time: {
                    id: 3,
                    time: 10,
                    created_by: 2,
                    updated_by: 2,
                    created_at: "2021-12-23T17:28:17.000000Z",
                    updated_at: "2021-12-23T17:28:17.000000Z",
                    deleted_at: null
                },
                service_lang: {
                    en: {
                        id: 623,
                        service_id: 312,
                        name: "Childrens Cuts  ",
                        lang: "en",
                        created_at: "2021-12-14T11:45:47.000000Z",
                        updated_at: "2021-12-14T11:45:47.000000Z",
                        deleted_at: null
                    },
                    ar: {
                        id: 624,
                        service_id: 312,
                        name: "قص شعر للأطفال",
                        lang: "ar",
                        created_at: "2021-12-14T11:45:47.000000Z",
                        updated_at: "2021-12-14T11:45:47.000000Z",
                        deleted_at: null
                    }
                }
            }
        ]
    },
    offers: [],
    listPhotos: [
        "https://system.pinksapp.com/public/uploads/zDfpFrTuIxtP9FtCZBepo5riZE5JbHRUNPPPYHtN-large.png"
    ],
    workingDays: [
        "Sunday"
    ],
    address: "Olaya St, Riyadh 12251, Saudi Arabia",
    arrDays: {
        "2023-02-09": {
            status: true,
            day: "Thursday",
            month: "February",
            PM: [
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM"
            ]
        },
        "2023-02-10": {
            status: true,
            day: "Friday",
            month: "February",
            AM: [
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM"
            ],
            PM: [
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM"
            ]
        },
        "2023-02-11": {
            status: true,
            day: "Saturday",
            month: "February",
            AM: [
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM"
            ],
            PM: [
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM"
            ]
        },
        "2023-02-12": {
            status: false,
            day: "Sunday",
            month: "February",
            message: "Vacations. not available"
        },
        "2023-02-13": {
            status: true,
            day: "Monday",
            month: "February",
            AM: [
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM"
            ],
            PM: [
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM"
            ]
        },
        "2023-02-14": {
            status: true,
            day: "Tuesday",
            month: "February",
            AM: [
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM"
            ],
            PM: [
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM"
            ]
        },
        "2023-02-15": {
            status: true,
            day: "Wednesday",
            month: "February",
            AM: [
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM"
            ],
            PM: [
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM"
            ]
        },
        "2023-02-16": {
            status: true,
            day: "Thursday",
            month: "February",
            AM: [
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM"
            ],
            PM: [
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM"
            ]
        },
        "2023-02-17": {
            status: true,
            day: "Friday",
            month: "February",
            AM: [
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM"
            ],
            PM: [
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM"
            ]
        }
    }
}
