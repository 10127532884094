import { CommonModule } from '@angular/common';
import { Component, Signal, computed, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { LanguageService } from 'app/shared/services';
import { ILanguageVm, Languages } from 'app/shared/utilities/models';

@Component({
  selector: 'language',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatMenuModule],
  templateUrl: './language.component.html',
})
export class LanguageComponent {
  private readonly _langService = inject(LanguageService);

  public readonly availableLangs: Signal<ILanguageVm[]> = signal(Languages);

  public activatedLang: Signal<ILanguageVm> = computed(
    () =>
      this.availableLangs().find(
        (l) => l?.id === this._langService.lang()
      ) as ILanguageVm
  );

  public onSelectLang = (lang: ILanguageVm): void => {
    this._langService.setLanguage(lang?.id);
  };
}
